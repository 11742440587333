
import React from "react";

const RemoteVideo = React.forwardRef((_, ref) => (
  <div className="remote-video-container">
    <video
      ref={ref}
      autoPlay
      className="remote-video"
    />
  
  </div>
));

export default RemoteVideo;

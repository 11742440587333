import React, { useEffect, useRef, useState } from "react";
import { TextareaAutosize } from "@mui/material";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";

const StyledTextarea = styled(TextareaAutosize)({
  fontFamily: `"SF Pro Text", "SF Pro Icons", system, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", Ubuntu, Cantarell, "Fira Sans", sans-serif`,
  width: "100%",
  padding: "8px 12px",
  borderRadius: "8px",

  fontSize: "15px",
  lineHeight: "1.5",
  resize: "none",
  overflowY: "auto",
  scrollbarWidth: "thin",
  scrollbarColor: "#3b4348 transparent",
  border: "none",
  "::placeholder": {
    color: "#8696a0", // Placeholder color
  },
  "&:hover": {
    border: "none",
  },
  "&:focus": {
    border: "none",
    outline: "none",
  },
});

const Typing = React.memo(
  ({
    AppTheme,
    textAreaRef,
    message,
    handleKeyPress,
    handleChange,
    setIsEmojiOn,
  }) => {
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const isTypingInArabic = (text) => {

      if (text === "" || text== undefined) return false;

      text = text.trim();
    
      // Check if the first character is in the range of RTL scripts
      if (text.length === 0) return false; // Handle empty strings
    
      const firstChar = text.charCodeAt(0);
      
      // Unicode ranges for common RTL scripts
      const isArabic = (firstChar >= 0x0600 && firstChar <= 0x06FF);
      const isHebrew = (firstChar >= 0x0590 && firstChar <= 0x05FF);
      const isPersian = (firstChar >= 0x0750 && firstChar <= 0x077F); // Additional Persian range
      const isUrdu = (firstChar >= 0x0600 && firstChar <= 0x06FF); // Urdu uses Arabic script
    
      return isArabic || isHebrew || isPersian || isUrdu;

      // if (text === "") return true;
      // // Regex to detect Arabic characters including extended ranges
      // const arabicRegex =
      //   /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB70-\uFBFF\uFE70-\uFEFF]/;
      // return arabicRegex.test(text);
    };
    return (
      <StyledTextarea
        ref={textAreaRef}
        maxLength={1000}
        value={message}
        sx={{
          backgroundColor: AppTheme === "dark" ? "#2a3942" : "#f0f2f5",
          color: AppTheme === "dark" ? "#e9edef" : "#3b4a54",
          direction:
            isTypingInArabic(message) ? "rtl" : "ltr", // Dynamically set text direction
          // textAlign:   i18n.language === "ar"?'end':'left',
          "&::placeholder": {
      textAlign: i18n.language==='en'?"left":'right', // Force placeholder text to align left
    },
        }}
        onKeyPress={handleKeyPress}
        onChange={handleChange}
        onFocus={() => setIsEmojiOn(false)}
        minRows={1}
        maxRows={5} // Limits auto-expansion to 5 rows
        placeholder={t("type_a_message")}
      />
    );
  },
  (prevProps, nextProps) => {
   return (
    prevProps.AppTheme === nextProps.AppTheme &&
    prevProps.textAreaRef === nextProps.textAreaRef &&
    prevProps.handleKeyPress === nextProps.handleKeyPress &&
    prevProps.handleChange === nextProps.handleChange &&
    prevProps.setIsEmojiOn === nextProps.setIsEmojiOn 
   )
  }
);


export default Typing;

import React, { useEffect } from 'react';
import { FaPhoneAlt, FaPhoneSlash } from 'react-icons/fa';
import  './RingingPopup.css';
import { useVideoChat } from "./Context/VideoChatContext";
const RingingPopup = ({  setVideoCall,videoCall }) => {

  const {
    localStream,
    setLocalStream,
    remoteStream,
    setRemoteStream,
    isMuted,
    setIsMuted,
    isVideoOff,
    setIsVideoOff,
    callStarted,
    setCallStarted,
    isRinging,
    setIsRinging,
    isAudioCall,
    setIsAudioCall,
    callId,
    setCallId,
    localVideoRef,
    remoteVideoRef,
    peerConnectionRef,
    iceCandidateQueue,
    setIsCallOpen,
    isCallOpen,
    callUtils,
    beforeacceptingcall,
    incomingCallerName,
    groupUserName
  } = useVideoChat();



  const {
    configuration,
    getUserMedia,
    setupPeerConnection,
    handleIncomingCall,
    acceptCall,
    rejectCall,
    handleAnswer,
    handleICECandidate,
    startCall,
    toggleMute,
    toggleVideo,
    endCall,
    userid,
    chatRoomid,
    socketRef,
  } = callUtils;

  
 

  return (
    <div className='ringing-popup'>
      <div className='ringing-content'>
        <p className="caller-name">{incomingCallerName?incomingCallerName:groupUserName} is calling...</p>
        {/* <div className="ringing-buttons">
          <button className="accept-button1" onClick={()=>{setVideoCall(true);beforeacceptingcall.current=true}}>
            <FaPhoneAlt className="icon" /> Accept
          </button>
          <button className="reject-button1"  onClick={()=>{
              socketRef.current.emit("end-call",{
              userId:userid.current,
              chatRoomId:chatRoomid.current,
              callId:callId
            });
            endCall();
            } }>
            <FaPhoneSlash className="icon" /> Reject
          </button>
        </div> */}
        <div className="ringing-buttons">  
          <button className="accept-button1" onClick={()=>{setVideoCall(true)}}>Ready to join</button>
        </div>
      </div>
    </div>
  );
};

export default RingingPopup;



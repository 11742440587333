// import React, { useEffect, useRef } from "react";
// import useHorizontalScroll from "./useHorizontalScroll ";
// import { useVideoChat } from "./Context/VideoChatContext";
//  const ThumbnailSlider = ({sliderRef,streamsArray = {},setSelectedStream,setCallerNameInGroup }) => {

//   const {
//     groupMembersForCall,
//     callUtils
//   } = useVideoChat();
  
//   useHorizontalScroll(sliderRef, 2); // Optional speed multiplier
// useEffect(()=>{
//   console.log("groupMembersForCall,groupMembersForCall",groupMembersForCall)
// },[])

//   return (
//     <div ref={sliderRef} className="thumbnailSlider">
   


// {streamsArray && Object.entries(streamsArray).map(([key, stream]) => (
//   <div
//     key={key}
//     data-key={`${key}-slider`}
//     className="thumbnailContainer"
//     onClick={() =>{
//       console.log('thumbnailcontainer',key);
//       setSelectedStream(stream)
//       groupMembersForCall?.find((item)=>{
//         if(item.userId==key)setCallerNameInGroup(item.userName)
//       })
//     }}
//   >
//     <video
//       autoPlay
//       muted
//       style={{ display: stream === 'none' ? 'none' : 'block' }}
//       ref={(el) => {
//         if (el && el.srcObject !== stream) el.srcObject = stream;
//       }}
//       className="thumbnailVideo"
//     />
//   </div>
// ))}
//   </div>
//   );
// };

// export default ThumbnailSlider;





import React, { useEffect } from "react";
import useHorizontalScroll from "./useHorizontalScroll";
import { useVideoChat } from "./Context/VideoChatContext";

const ThumbnailSlider = ({ sliderRef, streamsArray = {}, setSelectedStream, setCallerNameInGroup }) => {
  const { groupMembersForCall,remoteGroupVideoOff } = useVideoChat();

  useHorizontalScroll(sliderRef, 2); // Optional speed multiplier

  useEffect(() => {
    console.log("groupMembersForCall:", groupMembersForCall);
  }, [groupMembersForCall]);

  return (
    <div ref={sliderRef} className="thumbnailSlider">
      {streamsArray &&
        Object.entries(streamsArray).map(([key, stream]) => {
          const user = groupMembersForCall?.find((item) => item.userId === key);

          return (
            <div
              key={key}
              className="thumbnailContainer"
              onClick={() => {
                console.log("thumbnailContainer", key);
                setSelectedStream(stream);
                if (user) setCallerNameInGroup(user.userName);
              }}
            >
             { remoteGroupVideoOff[key]  &&
                <div className="avatarContainer"   style={{
                  zIndex: 10,
                  position: "absolute", // Ensure it layers above the video
                  height: "100%",
                  width: "100%", // Make it overlay the entire container
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "rgba(0, 0, 0, 0.5)", // Optional semi-transparent background
                }}>
                  {/* Profile Image */}
                  <div className="avatar">
                    {user?.profileUrl ? (
                      <img src={user.profileUrl} alt={`${user?.userName}'s avatar`} className="avatarImage" />
                    ) : (
                      <span className="avatarPlaceholder">{user?.userName?.charAt(0)}</span>
                    )}
                  </div>
                  {/* Display User Name */}
                  <div className="userName">{user?.userName}</div>
                </div>
                }
             
                <video
                  autoPlay
                  muted
                  style={{ display: remoteGroupVideoOff[key] ? "none" : "block" }}
                  ref={(el) => {
                    if (el && el.srcObject !== stream) el.srcObject = stream;
                  }}
                  className="thumbnailVideo"
                />
              
            </div>
          );
        })}
    </div>
  );
};

export default ThumbnailSlider;

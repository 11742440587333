import React, { useEffect, useRef, useState } from "react";
import {
  FaMicrophone,
  FaMicrophoneSlash,
  FaVideo,
  FaVideoSlash,
  FaPhoneSlash,
  FaPhoneAlt,
} from "react-icons/fa";
import { useVideoChat } from "./Context/VideoChatContext"; // Import the context hook
import "./VideoChat.css"; // Custom CSS for WhatsApp-like UI
import { Box } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import ThumbnailSlider from "./ThumbnailSlider";
import RemoteVideo from "./RemoteVideo";
import LocalVideo from "./LocalVideo";
import AudioRoom from "./AudioRoom";
import { Avatar } from "@mui/material";

// import AudioRoom from "./AudioRoom";
const VideoChat = ({ CallType, isOnline, chatType, chatData, receiverName,profileImg }) => {
  const {
    localStream,
    setLocalStream,
    remoteStream,
    setRemoteStream,
    isMuted,
    setIsMuted,
    isVideoOff,
    setIsVideoOff,
    callStarted,
    setCallStarted,
    isRinging,
    setIsRinging,
    isAudioCall,
    setIsAudioCall,
    callId,
    setCallId,
    localVideoRef,
    remoteVideoRef,
    peerConnectionRef,
    iceCandidateQueue,
    setIsCallOpen,
    isCallOpen,
    allOffers,

    isGroupCall,
    callUtils,
    remoteStreams,
    socket,
    incallingroom,
    callReceiver,
    incomingCallerName,
    setVideoCall,
    CallStartTime,
    beforeacceptingcall,
    localAudioRef,
    remoteAudioRef,
    mediaStreams,
    groupMembersForCall,
    setGroupMembersForCall,
    groupUserName,
    setCallReceiverBusy,
    callReceiverBusy,
    isRemoteVideoOff,
    incomingCallerPhoto,
    userNameProfile
  } = useVideoChat();

  const {
    configuration,
    getUserMedia,
    setupPeerConnection,
    handleIncomingCall,
    handleIncomingGroupCall,
    acceptCall,
    rejectCall,
    handleAnswer,
    handleICECandidate,
    startCall,
    toggleMute,
    toggleVideo,
    endCall,
    userid,
    chatRoomid,
    socketRef,
    startGroupCall,
    handleICECandidateGroup,
    handleAnswerGroup,
  } = callUtils;

  const [callerNameInGroup, setCallerNameInGroup] = useState("");
  const [secondsElapsed, setSecondsElapsed] = useState(0);
  const [selectedStream, setSelectedStream] = useState(null);
  const [activeUsers, setActiveUsers] = useState([]);
  const [enterInRoom,setEnterInRoom] = useState(false);
  const theme = useTheme();
  // const ringtoneRef = useRef(null);

  // Play ringtone when there's an incoming call
  // useEffect(() => {
  //   if (isRinging && ringtoneRef.current) {
  //     ringtoneRef.current.play();
  //   } else if (ringtoneRef.current) {
  //     ringtoneRef.current.pause();
  //     ringtoneRef.current.currentTime = 0; // Reset to start
  //   }
  // }, [isRinging]);

  useEffect(() => {
    if (callUtils.chatRoomid.current != null && callReceiver == true) {
      chatData?.find((item) => {
        if (item.chatRoomId === callUtils.chatRoomid.current) {
          setGroupMembersForCall(item?.groupDetails?.allUserDetails);
        }
      });
    }
  
  }, []);

  useEffect(()=>{
    let timeout;
     if(callReceiverBusy){
         

    timeout=setTimeout(()=>{
      setVideoCall(false)
      endCall()
  },3000)
 }
     
  // return ()=>{
  //   clearTimeout(timeout)
  // }
  },[callReceiverBusy])

  
  useEffect(() => {
    getUserMedia(isAudioCall);
    return ()=>{
      setEnterInRoom(false);
    }
  }, []);

  useEffect(() => {
    if (isCallOpen && !incallingroom) {
      let startTime = 0;
      if (CallStartTime) {
        startTime = Math.floor((Date.now() - CallStartTime) / 1000);
      }
      const interval = setInterval(() => {
        setSecondsElapsed((prevSeconds) => startTime + prevSeconds + 1);
      }, 1000);

      return () => clearInterval(interval);
    } else {
      setSecondsElapsed(0);
    }
  }, [incallingroom]);

  useEffect(() => {
    if (
      isOnline === true &&
      !callReceiver &&
      incallingroom &&
      chatType !== "GROUP"
    ) {
      startCall(isAudioCall);
    }
  }, [isOnline]);

  useEffect(() => {
    if (!selectedStream && Object.values(remoteStreams).length > 0) {
     setEnterInRoom(true)
      setSelectedStream(Object.values(remoteStreams)[0]);
      const caller = groupMembersForCall?.find(
        (item) => item.userId == Object.keys(remoteStreams)[0]
      );

      if (caller) {
        console.log("caller: ", caller);

        setCallerNameInGroup(caller.userName);
      }
    }

    if(selectedStream){
      let exists=Object.values(remoteStreams).includes(selectedStream)
      if(!exists && Object.values(remoteStreams).length > 0){
        setSelectedStream(Object.values(remoteStreams)[0]);
        const caller = groupMembersForCall?.find(
          (item) => item.userId == Object.keys(remoteStreams)[0]
        );
  
        if (caller) {
          console.log("caller: ", caller);
  
          setCallerNameInGroup(caller.userName);
        }
      }
    }
  }, [remoteStreams, groupMembersForCall]);

  const handleEndCall = () => {
    if (isGroupCall.current) {
      if(incallingroom){
        socketRef.current.emit("exit-group-call", {
          userId: userid.current,
          chatRoomId: chatRoomid.current,
          callId: callId, 
        });
        return;
      }
      if (Object.keys(remoteStreams).length > 0) {
        socketRef.current.emit("exit-group-call", {
          userId: userid.current,
          chatRoomId: chatRoomid.current,
          callId: callId, 
        });
      } else {
        socketRef.current.emit("end-call", {
          userId: userid.current,
          chatRoomId: chatRoomid.current,
          callId: callId,
        });
      }
    } else {
      socketRef.current.emit("end-call", {
        userId: userid.current,
        chatRoomId: chatRoomid.current,
        callId: callId,
      });
    }
  };
  const sliderRef = useRef(null);

  const renderMainVideo = () => (
    <div className="fullScreenContainer" style={{ position: "relative" }}>
    <video
        autoPlay
        ref={(el) => {
          if (el && el.srcObject !== selectedStream)
            el.srcObject = selectedStream;
        }}
        style={{ maxHeight: "60vh" }}
        className="fullScreenVideo"
      />
      <div
        style={{
          zIndex: 1,
          position: "absolute",
          bottom: "20px", // Adjust distance from the bottom
          left: "50%",
          transform: "translateX(-50%)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.7)", // Semi-transparent background
          color: "white",
          padding: "10px 16px",
          borderRadius: "14px",
          fontSize: "14px", // Optional: adjust font size for visibility
          whiteSpace: "nowrap", // Prevent text wrap
          //  marginBottom: '10px'
        }}
      >
        {callerNameInGroup}
      </div>
    </div>
  );

  return (
   
    <div className="video-chat-container">
      {/* <audio ref={ringtoneRef} src="/audio/ringtone.mp3" loop /> */}

      {Object.keys(remoteStreams).length > 0 && (
        <div className="videoChatContainer">
          {(!isAudioCall && selectedStream) && renderMainVideo()}
          {(!isAudioCall && selectedStream) && (
            <ThumbnailSlider
              sliderRef={sliderRef}
              streamsArray={remoteStreams}
              setSelectedStream={setSelectedStream}
              setCallerNameInGroup={setCallerNameInGroup}
            />
          )}
           {(isAudioCall && selectedStream) && <AudioRoom streamsArray={remoteStreams} groupMembersForCall={groupMembersForCall}/>}
        </div>
      )}

      {!isAudioCall && 
      <>
      {isRemoteVideoOff==true &&
      <div style={{zIndex:3,display:'flex',justifyContent:'center',alignItems:'center',height:'100%',flexDirection:'column'}} >
         <Avatar
            src={incomingCallerPhoto?incomingCallerPhoto:profileImg}
            alt={''}
            sx={{
              width: 120,
              height: 120,
              [theme.breakpoints.down("sm")]: {
                width: 80,
                height: 80,
              },
            }}
          />
        
      </div>}
      <RemoteVideo ref={remoteVideoRef} />
      </>
       }

      {CallStartTime != null && (
        <div
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            right: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // backgroundColor: 'rgba(0, 0, 0, 0.7)',
            color: "white",
            padding: "10px",
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box sx={{ fontSize: "14px" }}>
              {incomingCallerName ? incomingCallerName : groupUserName}
            </Box>

            <Box sx={{ fontSize: "12px", color: "#8696A0" }}>
  {Math.floor(secondsElapsed / 3600)}:
  {String(Math.floor((secondsElapsed % 3600) / 60)).padStart(2, "0")}:
  {String(secondsElapsed % 60).padStart(2, "0")}
</Box>

          </Box>
        </div>
      )}

      {!isAudioCall && <LocalVideo isVideoOff={isVideoOff}
  avatarUrl={userNameProfile} 
  username={receiverName}  
    ref={localVideoRef} />}


      {callStarted && !isGroupCall.current && isAudioCall && !isRinging && !incallingroom && <div className="incoming-call-popup">
          <Box sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
           <Avatar
            src={incomingCallerPhoto?incomingCallerPhoto:profileImg}
            alt={'img'}
            sx={{
              width: 120,
              height: 120,
              [theme.breakpoints.down("sm")]: {  
                width: 80,
                height: 80,
              },
            }}
          />
          </Box>
       </div>}

      {isRinging && (
        <div className="incoming-call-popup">
          <Box sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
           <Avatar
            src={incomingCallerPhoto}
            alt={''}
            sx={{
              width: 120,
              height: 120,
              [theme.breakpoints.down("sm")]: {
                width: 80,
                height: 80,
              },
            }}
          />
          </Box>
          <p>{incomingCallerName ? incomingCallerName : groupUserName}</p>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",

              color: "white",
              padding: "10px 20px",
            }}
          >
            <button className="accept-button" onClick={acceptCall}>
              <FaPhoneAlt color="white" />
            </button>
            <button
              className="reject-button"
              onClick={() => {
                if (isGroupCall.current) {
                  socketRef.current.emit("exit-group-call", {
                    userId: userid.current,
                    chatRoomId: chatRoomid.current,
                    callId: callId, 
                  });
                }else{
                socketRef.current.emit("end-call", {
                  userId: userid.current,
                  chatRoomId: chatRoomid.current,
                  callId: callId,
                });
              }
                endCall();
              }}  
            >
              <FaPhoneSlash color="white" />
            </button>
          </Box>
        </div>
      )}

      {callStarted && !callReceiverBusy && (chatType!=='GROUP'?!callReceiverBusy:true) && (
        <div className="call-controls" style={{zIndex:10}}>
          <button onClick={toggleMute} className="control-button">
            {isMuted ? <FaMicrophoneSlash /> : <FaMicrophone />}
          </button>
          {!isAudioCall && (
            <button onClick={toggleVideo} className="control-button">
              {isVideoOff ? <FaVideoSlash /> : <FaVideo />}
            </button>
          )}
          <button
            onClick={() => {
              handleEndCall();
              endCall();
            }}
            className="control-button"
          >
            <FaPhoneSlash />
          </button>
        </div>
      )}



      {incallingroom && !callReceiver && (chatType!=='GROUP'?!callReceiverBusy:true) && (    
        <div    style={{
          position: "absolute",
          top: "0",
          left: "0",
          right: "0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // backgroundColor: 'rgba(0, 0, 0, 0.7)',
          color: "white",
          padding: "10px",
        }}>
          
          <p>
            {chatType === "GROUP"
              ? "Ringing"
              : isOnline === true
              ? "Ringing"
              : "Calling"}
            <span className="dot">.</span>
            <span className="dot">.</span>
            <span className="dot">.</span>
          </p>
        </div>
      )}

      {incallingroom && !callReceiver && (chatType!=='GROUP'?!callReceiverBusy:true) && (    
        <div className="outGoing-call-popup">
          <Avatar
            src={profileImg}
            alt={''}
            sx={{
              width: 120,
              height: 120,
              [theme.breakpoints.down("sm")]: {
                width: 80,
                height: 80,
              },
            }}
          />
          <p>{receiverName}</p>
         
        </div>
      )}

      {(chatType!=='GROUP'?callReceiverBusy:false) && (   
        <div className="outGoing-call-popup">
          <p>
            The person is busy on another call.
          </p>
        </div>
      )}

      {(isGroupCall.current && !incallingroom && enterInRoom && Object.values(remoteStreams).length == 0) && (   
        <div className="outGoing-call-popup">
          <p>
            No one in the room.
          </p>
        </div>
      )}
      
      {isAudioCall && <audio ref={localAudioRef} autoPlay muted />}
      {isAudioCall && <audio ref={remoteAudioRef} autoPlay />}
    </div>
   
  );
};

export default VideoChat;

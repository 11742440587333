
// import React from "react";

// const LocalVideo = React.forwardRef((_, ref) => (
//   <div className="local-video-container">
//     <video ref={ref} autoPlay muted className="local-video"  />
//   </div>
// ));

// export default LocalVideo;



import { Avatar } from "@mui/material";
import React, { useEffect } from "react";

const LocalVideo = React.forwardRef(({ isVideoOff, avatarUrl, username }, ref) => {

  useEffect(()=>{
    console.log('isVideoOffisVideoOff',isVideoOff,'refrefrefref',ref)
    
  },[isVideoOff,ref])

  return(
  <div className="local-video-container">
    {isVideoOff==true &&
      <div style={{zIndex:3}} className="avatar-container-localStream">
        <Avatar src={avatarUrl}  sx={{ width: 80, height: 80, borderRadius: '50%', objectFit: 'cover' }}  className="avatar-image-localStream" />
        <p className="avatar-username-localStream">You</p>
      </div>}
    
      <video ref={ref} autoPlay muted className="local-video" />
    
  </div>
)
});

export default LocalVideo;

import React from 'react';
import { Dialog, Box, Button } from '@mui/material';

export default function ExitGroupDialog({ exitGroupDialog, setExitGroupDialog, handleExistGroup, AppTheme }) {
  return (
    <Dialog
      open={exitGroupDialog}
      onClose={() => setExitGroupDialog(false)}
      PaperProps={{
        sx: {
          borderRadius: '10px', // Apply border radius
          backgroundColor: AppTheme === 'dark' ? '#202c33' : '#f0f2f5', // Background color based on theme
          padding: '20px', // Optional padding for a better look
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent:'center',
          color: AppTheme === 'light' ? '#111b21' : '#e9edef',
        }}
      >
        <Box sx={{  textAlign: 'center', fontSize:'20px',fontWeight: 400, color: AppTheme === 'dark' ? '#ffffff' : '#000000', marginBottom: '20px' }}>
          Do you want to exit the group?
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '80%',fontSize:'14px' }}>
          <Button sx={{
      fontSize: '14px',
      backgroundColor: '#e0e0e0', // Default background color
      color: '#000', // Button text color
      padding: '10px 20px',
      borderRadius: '61px',
      transition: 'background-color 0.3s ease, transform 0.2s', // Smooth hover transition
      '&:hover': {
        backgroundColor: '#bdbdbd', // Hover background color
        transform: 'scale(1.05)', // Slight scale effect on hover
      },
    }} onClick={() => setExitGroupDialog(false)}>No</Button>
          <Button  sx={{
      fontSize: '14px',
      backgroundColor: '#EA0038', // Default background color for "Yes" button
      color: '#fff', // Button text color
      padding: '10px 20px',
      borderRadius: '61px',
      transition: 'background-color 0.3s ease, transform 0.2s', // Smooth hover transition
      '&:hover': {
        backgroundColor: '#c0002f', // Darker red for hover
        transform: 'scale(1.05)', // Slight scale effect on hover
      },
    }}  onClick={handleExistGroup}>
            Yes
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

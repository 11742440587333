// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ringing-popup {
  
    top: '0';
    left: 0;
    right: 0;
    background-color: rgba(0, 128, 0, 0.9); 
    color: white;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
  
  .ringing-content {
    text-align: center;
  }
  
  .caller-name {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .ringing-buttons {
    display: flex;
    justify-content: space-around;
  }
  
  .accept-button1, .reject-button1 {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    font-size: 16px;
    margin: 0 10px;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .accept-button1 {
    background-color: #4CAF50; 
    color: white;
  }
  
  .reject-button1 {
    background-color: #f44336;
    color: white;
  }
  
  .accept-button1:hover {
    background-color: #45a049;
  }
  
  .reject-button1:hover {
    background-color: #e53935;
  }
  
  .icon {
    margin-right: 8px;
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/RingingPopup.css"],"names":[],"mappings":"AAAA;;IAEI,QAAQ;IACR,OAAO;IACP,QAAQ;IACR,sCAAsC;IACtC,YAAY;IACZ,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,wCAAwC;EAC1C;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,6BAA6B;EAC/B;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,eAAe;IACf,cAAc;IACd,YAAY;IACZ,mBAAmB;IACnB,eAAe;IACf,sCAAsC;EACxC;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,iBAAiB;EACnB","sourcesContent":[".ringing-popup {\r\n  \r\n    top: '0';\r\n    left: 0;\r\n    right: 0;\r\n    background-color: rgba(0, 128, 0, 0.9); \r\n    color: white;\r\n    padding: 20px;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    z-index: 1000;\r\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);\r\n  }\r\n  \r\n  .ringing-content {\r\n    text-align: center;\r\n  }\r\n  \r\n  .caller-name {\r\n    font-size: 18px;\r\n    font-weight: bold;\r\n    margin-bottom: 15px;\r\n  }\r\n  \r\n  .ringing-buttons {\r\n    display: flex;\r\n    justify-content: space-around;\r\n  }\r\n  \r\n  .accept-button1, .reject-button1 {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    padding: 10px 20px;\r\n    font-size: 16px;\r\n    margin: 0 10px;\r\n    border: none;\r\n    border-radius: 25px;\r\n    cursor: pointer;\r\n    transition: background-color 0.3s ease;\r\n  }\r\n  \r\n  .accept-button1 {\r\n    background-color: #4CAF50; \r\n    color: white;\r\n  }\r\n  \r\n  .reject-button1 {\r\n    background-color: #f44336;\r\n    color: white;\r\n  }\r\n  \r\n  .accept-button1:hover {\r\n    background-color: #45a049;\r\n  }\r\n  \r\n  .reject-button1:hover {\r\n    background-color: #e53935;\r\n  }\r\n  \r\n  .icon {\r\n    margin-right: 8px;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import { useEffect } from "react";

const useHorizontalScroll = (ref, speed = 1) => {
  useEffect(() => {
    const slider = ref.current;
    if (slider) {
      const handleWheelScroll = (e) => {
        if (e.deltaY !== 0) {
          e.preventDefault();
          slider.scrollLeft += e.deltaY * speed;
        }
      };
      slider.addEventListener("wheel", handleWheelScroll, { passive: false });
      return () => slider.removeEventListener("wheel", handleWheelScroll);
    }
  }, [ref, speed]);
};

export default useHorizontalScroll;

import React, {
  createContext,
  useState,
  useRef,
  useContext,
  useEffect,
} from "react";
import toast from "react-hot-toast";

// Create VideoChat context
const VideoChatContext = createContext();

// Export custom hook for easy access to context
export const useVideoChat = () => {
  return useContext(VideoChatContext);
};

export const VideoChatProvider = ({ children }) => {
  const socketRef = useRef(null);
  const chatRoomid = useRef(null);
  const userid = useRef(null);
  let socket = null;
  useEffect(() => {
    socket = socketRef?.current;
  }, [socketRef?.current]);
  const [timerUpdate,setTimerUpdate]=useState(false)
  const [localStream, setLocalStream] = useState(null);
  const [remoteStream, setRemoteStream] = useState(null);
  const [isMuted, setIsMuted] = useState(false);
  const [isVideoOff, setIsVideoOff] = useState(false);
  const [callStarted, setCallStarted] = useState(false);
  const [isRinging, setIsRinging] = useState(false);
  const [isAudioCall, setIsAudioCall] = useState(false);
  const [callId, setCallId] = useState(null);
  const [isCallOpen, setIsCallOpen] = useState(false);
  const isCallOpenRef = useRef(false)
  const localVideoRef = useRef(null);
  const localAudioRef = useRef(null);
  const remoteVideoRef = useRef(null);
  const remoteAudioRef = useRef(null);
  const peerConnectionRef = useRef(null);
  const iceCandidateQueue = useRef([]);
  const amICalling = useRef(false)
  const amIStartGroupCall = useRef(false)
  const [callReceiver,setCallReceiver]=useState(false);
  const [incallingroom,setincallingroom]=useState(false);
  const [groupUserName,setGroupUserName]=useState('')
  // const [participants, setParticipants] = useState(['1060','1070','1001']); 
  const [participants, setParticipants] = useState([]);
  const [remoteStreams, setRemoteStreams] = useState({});
  const peerConnections = useRef({});
  const allOffers = useRef({})
  const myAllOffers = useRef({})
  const isGroupCall = useRef(false)
  const iceCandidateQueues = useRef({});
  const allIceCandidateQueues = useRef({});
  const videoRefs = useRef([])
  const [CallStartTime,setCallStartTime]= useState(null)
  const [videoCall, setVideoCall] = useState(false)
  const [userName,setUserName]=useState('')
  const [userNameProfile,setUserNameProfile] = useState('')
  const [incomingCallerName,setIncomingCallerName] = useState('')
  const [incomingCallerPhoto,setIncomingCallerPhoto] = useState('')
  const beforeacceptingcall=useRef(null);
  const offerArray = useRef([])
  const [mediaStreams, setMediaStreams] = useState([]);
  const [groupMembersForCall,setGroupMembersForCall] =useState([])
  const [activeUsers, setActiveUsers] = useState([]);
  const [callReceiverBusy,setCallReceiverBusy]= useState(false)
  const [isRemoteVideoOff, setIsRemoteVideoOff] = useState(false);
  const [remoteGroupVideoOff,setRemoteGroupVideoOff] = useState({})
  const configuration = {
    iceServers: [
      {
        urls: "stun:stun.l.google.com:19302" // Public STUN server
      },
      // You can add TURN servers for NAT traversal if needed
    ]
  };

  const getUserMedia = async (isAudioCall) => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: !isAudioCall,
        audio: true,
      });
      
      if (!isAudioCall && localVideoRef.current) {
        localVideoRef.current.srcObject = stream; // Display video if it's a video call
      }else{
        if (localAudioRef.current) localAudioRef.current.srcObject = stream;
      }
      setLocalStream(stream);
      setMediaStreams((prevStreams) => [...prevStreams, stream]);

      return true 
    } catch (error) {
      console.error("Error accessing media devices.", error);
      return false
    }
  };

  const setupPeerConnection = () => {

    // console.log("RTCPeerConnection  established");
    const peerConnection = new RTCPeerConnection(configuration);
    peerConnectionRef.current = peerConnection;
    // console.log("RTCPeerConnection  established",peerConnection);
    if (localStream) {
      localStream?.getTracks()?.forEach((track) => peerConnection.addTrack(track, localStream));
    } else {
      console.error("Local stream not available. Unable to add tracks.");
    }

   // On the receiver side
   peerConnection.ontrack = (event) => {
    setRemoteStream(event.streams[0]);
    console.log("event.streams[0]: ", event.streams[0]);


    if (!isAudioCall && remoteVideoRef.current) {
      remoteVideoRef.current.srcObject = event.streams[0];
      

     
      const remoteVideoTracks = event.streams[0].getVideoTracks();
      if (remoteVideoTracks.length > 0) {  // Check if there's a video track
        const remoteVideoTrack = remoteVideoTracks[0];
      // Listen for onmute and onunmute events to detect changes in the sender's video state
      remoteVideoTrack.onmute = () => {
        console.log("Sender turned off their video.");
        if(remoteVideoRef.current)
        remoteVideoRef.current.style.display = "none"; // Hide video element
        setIsRemoteVideoOff(true);
      };
    
      remoteVideoTrack.onunmute = () => {
        console.log("Sender turned on their video.");
        setIsRemoteVideoOff(false);
        if(remoteVideoRef.current)
        remoteVideoRef.current.style.display = "block"; // Show video element
      };
    }
    }
    else{
      if (remoteAudioRef.current) {
        remoteAudioRef.current.srcObject = event.streams[0];
      } else {
        console.error("remoteAudioRef is not set.");
      }
    }
  }



    peerConnection.onicecandidate = (event) => {
      // console.log("ICE candidate event fired");
      if (event.candidate) {
        // console.log("event.candidate: ", event.candidate);
        
        socketRef.current.emit("ice-candidate", {
          candidate: event.candidate,
          chatRoomId: chatRoomid.current,
          userId: userid.current,
        });
      }
    };

    peerConnection.onicegatheringstatechange = () => {
      // console.log("ICE gathering state: ", peerConnection.iceGatheringState);
    };

    peerConnection.oniceconnectionstatechange = () => {
      // console.log("ICE connection state: ", peerConnection.iceConnectionState);
    };
    

    return peerConnection;
  };

  const handleIncomingCall = async(data) => {
    const { offer, callId: incomingCallId ,chatRoomId,userName,callType } = data;
    setIsAudioCall(callType==='VIDEO'?false:true)
    setIsCallOpen(true)
   await getUserMedia(callType==='VIDEO'?'false':'true');    
    setIncomingCallerPhoto(data.userProfile)
    setIncomingCallerName(userName);
    setIsRinging(true);
    chatRoomid.current = chatRoomId
    window.incomingOffer = offer; // Save the incoming offer
    setCallId(incomingCallId); // Set call ID for later use
    setCallReceiver(true)
    setincallingroom(true)
  };

  const handleIncomingGroupCall = (data,chatData=[]) => {
    const { offer, callId: incomingCallId ,chatRoomId } = data;
    setIsRinging(true);
    chatRoomid.current = chatRoomId
    setIsAudioCall(data.callType==='VIDEO'?false:true)
    // window.incomingOffer = offer; // Save the incoming offer
    setGroupUserName(data.groupName) 
    setIncomingCallerPhoto(data.groupProfile)
    setCallId(incomingCallId); // Set call ID for later use
    setCallReceiver(true)
    setincallingroom(true)
    setIsCallOpen(true)
  };

  useEffect(()=>{
     if(timerUpdate){
      setCallStartTime(Date.now());
     }
  },[timerUpdate])

  const acceptCall = async () => {
    setIsRinging(false); // Stop ringing
    setincallingroom(false)
   let media =  await getUserMedia(isAudioCall); // Get user media before accepting the call
   console.log("isAudioCall: ", isAudioCall);
   if(!media) return
    if(isGroupCall.current){
    
      let groupOffers = allOffers.current[chatRoomid.current];
      
      participants.map(async (item)=>{          
         if(groupOffers && groupOffers[item] && item != userid.current){
          console.log("groupOffers: ", groupOffers);
          const peerConnection = setupPeerConnectionForGroup(item,'answer');

          // Set the remote description to the saved incoming offer
          await peerConnection.setRemoteDescription(
            new RTCSessionDescription(groupOffers[item].offer)
          );
      
          iceCandidateQueues.current[item]?.forEach((item) =>
            {
            peerConnection.addIceCandidate(new RTCIceCandidate(item.candidate))}
          );
          iceCandidateQueues.current[item] = [];
      
          // Create and send answer
          setCallReceiver(false);
          const answer = await peerConnection.createAnswer();
          await peerConnection.setLocalDescription(answer);
          socketRef.current.emit("join-group-call", {
            answer: peerConnection.localDescription,
            chatRoomId: chatRoomid.current,
            userId: userid.current,
            callId: callId,
            to:item,
            createdAt : new Date().getTime()
          });
          
          setCallStarted(true); // Mark call as started
         }else{
          const peerConnection = setupPeerConnectionForGroup(item);
          const offer = await peerConnection.createOffer();
          await peerConnection.setLocalDescription(offer);

          const createdAt = new Date().getTime()
          socketRef.current.emit("offer-for-group-call", {
            offer: peerConnection.localDescription,
            chatRoomId: chatRoomid.current,
            userId: userid.current,
            callType: isAudioCall ? "AUDIO" : "VIDEO",
            to:item,
            createdAt: createdAt,
            participants:participants,
           
            callId:callId
          });
          myAllOffers.current[chatRoomid.current] = {[item]:{

            answer: peerConnection.localDescription,
            chatRoomId: chatRoomid.current,
            userId: userid.current,
            callId: callId,
            to:item,
            createdAt : createdAt
          },...myAllOffers.current[chatRoomid.current]}
      
             
    
         }
      })
      
      
    }else{

    
    const peerConnection = setupPeerConnection();

    // Set the remote description to the saved incoming offer
    await peerConnection.setRemoteDescription(
      new RTCSessionDescription(window.incomingOffer)
    );

    iceCandidateQueue.current?.forEach((candidate) =>
      peerConnection.addIceCandidate(new RTCIceCandidate(candidate))
    );
    iceCandidateQueue.current = [];

    // Create and send answer
    const answer = await peerConnection.createAnswer();
    await peerConnection.setLocalDescription(answer);
    socketRef.current.emit("answer", {
      answer: peerConnection.localDescription,
      chatRoomId: chatRoomid.current,
      userId: userid.current,
      callId: callId,
      userName:userName
    });

    setCallStarted(true); // Mark call as started

    }
    
  };


  const handleReAnswer = async (data) => {
    const {callId,chatRoomId,offer,to,userId:sendId} = data


      const peerConnection = setupPeerConnectionForGroup(sendId,'answer');

      // Set the remote description to the saved incoming offer
      await peerConnection.setRemoteDescription(
        new RTCSessionDescription(offer)
      );

      allIceCandidateQueues.current[sendId]?.forEach((item) =>
        {
        peerConnection.addIceCandidate(new RTCIceCandidate(item.candidate))}
      );
      allIceCandidateQueues.current[sendId] = [];
  

      const answer = await peerConnection.createAnswer();
      await peerConnection.setLocalDescription(answer);
      const createdAt = new Date().getTime()
      socketRef.current.emit("join-group-call", {
        answer: peerConnection.localDescription,
        chatRoomId: chatRoomid.current,
        userId: userid.current,
        callId: callId,
        to:sendId,
        createdAt : createdAt
      });


    
  }


  const rejectCall = () => {
    setIsRinging(false); // Stop ringing
    socketRef.current.emit("callRejected"); // Inform the caller that the call was rejected
  };

  const handleAnswer = async (data) => {
    console.log("data: ans", data);
    if(!peerConnectionRef.current) return
    // let isStream = await getUserMedia()
    if(localStream){
      setIsCallOpen(false)
      return 
    }
    setIncomingCallerName(data.userName)
    setCallId(data.callId)
    setincallingroom(false)
    // setCallStartTime(data.callStartTime)
    const { answer } = data;

    await peerConnectionRef.current.setRemoteDescription(
      new RTCSessionDescription(answer)
    );
  };


  const handleICECandidateGroup = async (data) => {
    if (isCallOpenRef.current && chatRoomid.current!=data.chatRoomId) {
      console.log(data.chatRoomId,'&&&',chatRoomid.current,"isCallOpen: inside ice-candidate-in-group-call", isCallOpen);
        return
    }
    console.log(data.chatRoomId,'&&&',callUtils.chatRoomid.current,"isCallOpen: outside ice-candidate-in-group-call", isCallOpen);
    const { candidate,createdAt,to,from } = data;
    // if(!allIceCandidateQueues.current[from]){
    //   allIceCandidateQueues.current[from] = []
    // } 
    // allIceCandidateQueues.current[from].push(data)
    if(!iceCandidateQueues.current[from]){
      iceCandidateQueues.current[from] = []
    } 
    if (peerConnections.current[from]) {
      if (peerConnections.current[from].remoteDescription) {
        console.log("iceCandidateQueues.current: 1", iceCandidateQueues.current);
        await peerConnections.current[from].addIceCandidate(
          new RTCIceCandidate(candidate)
        );
      } else {
        console.log("iceCandidateQueues.current: 2", iceCandidateQueues.current);
        iceCandidateQueues.current[from].push({candidate,createdAt});
      }
    } else {
      iceCandidateQueues.current[from].push({candidate,createdAt});
      console.log("iceCandidateQueues.current: ", iceCandidateQueues.current);
    }


  // } else {
  //   iceCandidateQueues.current[peerUserId].push(candidate);
  // }
  };

  const handleAnswerGroup = async (data) => {
    if(!peerConnections.current[data.userId]) return
    try {
    setincallingroom(false)
    const { answer } = data;
    if(!peerConnections.current[data.userId]){}
    await peerConnections.current[data.userId].setRemoteDescription(
      new RTCSessionDescription(answer)
    );
    if(iceCandidateQueues.current[data.userId]){
      console.log("iceCandidateQueues: ", remoteStreams);
      iceCandidateQueues.current[data.userId].map(item=>{
        peerConnections.current[data.userId].addIceCandidate(
          new RTCIceCandidate(item.candidate)
        );
      })
      iceCandidateQueues.current[data.userId]=[]
      
    }
  }catch (error) {
    console.error("Error setting remote description:", error,"jklsdjfklsdjfklsdjf",data.userId,"urioweurioweu",data.answer);

    
  }
  };

  const handleICECandidate = async (data) => {
    
    const { candidate } = data;
    if (peerConnectionRef.current) {
      if (peerConnectionRef.current.remoteDescription) {
        console.log('lo candidate aa gya1')
        await peerConnectionRef.current.addIceCandidate(
          new RTCIceCandidate(candidate)
        );
      } else {
        console.log('lo candidate aa gya2')
        iceCandidateQueue.current.push(candidate);
      }
    } else {
      console.log('lo candidate aa gya3')
      iceCandidateQueue.current.push(candidate);
    }
  };




  const startCall = async (isAudioCallp) => {
    console.log('isaudiocall',isAudioCallp)
    setIsAudioCall(isAudioCallp)
    let isStream = await getUserMedia(isAudioCallp)
    // console.log("isStream: ", isStream);
    if(!isStream){
      toast.error(`${isAudioCallp?'Microphone':'Camra'} is not accessable,cannot start call.`);
      setIsCallOpen(false)
      setVideoCall(false)
      return 
    }
    
 
    isCallOpen===true?setIsCallOpen(1): setIsCallOpen(true)
   
    amICalling.current = true
    
  };
  const [inactivityTimers, setInactivityTimers] = useState({});

  const handleOnTrack = (participantId, stream) => {
    setActiveUsers((prevActiveUsers) => {
      if (!prevActiveUsers.includes(participantId)) {
        return [...prevActiveUsers, participantId];
      }
      return prevActiveUsers;
    });
  
    // Create an AudioContext and AnalyserNode for audio analysis
    const audioContext = new AudioContext();
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 512; // Lower size for faster response, adjust if needed
  
    // Connect the stream to the audio context and analyser
    const source = audioContext.createMediaStreamSource(stream);
    source.connect(analyser);
  
    // Analyze the audio levels continuously
    const dataArray = new Uint8Array(analyser.frequencyBinCount);
  
    const checkAudioActivity = () => {
      analyser.getByteFrequencyData(dataArray);
      const volume = dataArray.reduce((sum, value) => sum + value, 0);
  
      if (volume > 100) { // Adjust threshold as needed
        // Participant is speaking
        setActiveUsers((prevActiveUsers) => {
          if (!prevActiveUsers.includes(participantId)) {
            return [...prevActiveUsers, participantId];
          }
          return prevActiveUsers;
        });
  
        // Clear the inactivity timer if they are speaking
        if (inactivityTimers[participantId]) {
          clearTimeout(inactivityTimers[participantId]);
        }
  
        // Set a new inactivity timer to remove the user when silent
        const timer = setTimeout(() => {
          setActiveUsers((prevActiveUsers) =>
            prevActiveUsers.filter((user) => user !== participantId)
          );
        }, 3000); // Adjust timeout as needed
  
        setInactivityTimers((prevTimers) => ({
          ...prevTimers,
          [participantId]: timer,
        }));
      } else {
        // No speaking detected; clear if still active after timeout
      }
  
      // Continue checking audio activity
      requestAnimationFrame(checkAudioActivity);
    };
  
    // Start the audio activity check loop
    checkAudioActivity();
  };
  

  const setupPeerConnectionForGroup = (participantId,offerType = 'offer') => {
    const peerConnection = new RTCPeerConnection(configuration);
    if (localStream) {
        localStream?.getTracks()?.forEach((track) => {
            peerConnection.addTrack(track, localStream);
        });
    }

    // Set up event listeners for the peer connection
    peerConnection.ontrack = (event) => {
      console.log("peerConnection.ontrack : checking" );
  
      setTimerUpdate(true)
      isAudioCall &&  handleOnTrack(participantId,event.streams[0]);


      const stream = event.streams[0];

      // Listen for changes in video track state
      const videoTrack = stream.getVideoTracks()[0];
      if (videoTrack) {
        videoTrack.onmute = () => {
          console.log(`Video muted for participant: ${participantId}`);
          setRemoteGroupVideoOff((prevStreams) => ({
            ...prevStreams,
            [participantId]: true, // Indicate video is off
          }));
          // setRemoteStreams((prevStreams) => ({
          //   ...prevStreams,
          //   [participantId]: "none", // Indicate video is off
          // }));
        };
    
        videoTrack.onunmute = () => {
          console.log(`Video unmuted for participant: ${participantId}`);
          setRemoteGroupVideoOff((prevStreams) => ({
            ...prevStreams,
            [participantId]: false, // Indicate video is off
          }));
          // setRemoteStreams((prevStreams) => ({
          //   ...prevStreams,
          //   [participantId]: stream, // Reset to the actual stream
          // }));
        };
      }

 // Update remote streams initially
 setRemoteStreams((prevStreams) => ({
  ...prevStreams,
  [participantId]: stream,
}));     
    };

    

    peerConnection.onicecandidate = (event) => {
        if (event.candidate) {
            console.log("Group ICE candidate event fired for participant:", participantId);
            socketRef.current.emit("ice-candidate-in-group-call", {
                candidate: event.candidate,
                chatRoomId: chatRoomid.current,
                userId: userid.current,
                to: participantId,
                createdAt: new Date().getTime(),
                offerType: offerType,
            });
        }
    };

    peerConnections.current[participantId] = peerConnection;
    return peerConnection;
};

const startGroupCall = async (isAudioCallp) => {
  setIsAudioCall(isAudioCallp)
  let isStream = await getUserMedia(isAudioCallp)
  if(!isStream){
    toast.error(`${isAudioCallp?'Microphone':'Camra'} is not accessable,cannot start call.`);
    setIsCallOpen(false)
    setVideoCall(false)
    console.error("Local stream not available. Cannot start call.");
    return 
  }
  
  // await getUserMedia(false); // Enable video and audio
  // await Promise.all(participants.map(participantId => {
  //   if(participantId != userid.current){
  //     setupPeerConnectionForGroup(participantId)
  //   }
  // }));
  // if (!localStream) {
  //   console.error("Local stream not available. Cannot start call.");
  //   return;
  // }
  setIsCallOpen(true)
  amIStartGroupCall.current = true
  
};





  useEffect(()=>{
      console.log('iscallopen',isCallOpen)
      isCallOpenRef.current = isCallOpen
  },[isCallOpen])



  useEffect( ()=>{


    let callFunction = async ()=>{
      const peerConnection =  setupPeerConnection();
      const offer = await peerConnection.createOffer();
      await peerConnection.setLocalDescription(offer);
      setincallingroom(true);
      // Emit the offer event to the backend
      setCallReceiver(false)
      socketRef.current.emit("offer", {
        offer: peerConnection.localDescription,
        chatRoomId: chatRoomid.current,
        userId: userid.current,
        callType: isAudioCall ? "AUDIO" : "VIDEO",
        userName:userName
      },(response)=>{
        console.log("response.succes: ", response);
        if(response.success){
          
        
          setCallId(response.callId)
        }
        if(!response.success){
          if(response.message==="This user is on another call."){
            setVideoCall(false)
            toast.error('This Person is busy on another call.')
          }
        }
      });
      setCallStarted(true); // Set call as started
    }
   
    if(isCallOpen && amICalling.current == true){
      callFunction()
    }
  },[isCallOpen,amICalling])

  useEffect( ()=>{
    let callFunction = async ()=>{
      await Promise.all(participants.map(async participantId => {
        if(participantId != userid.current){
          const peerConnection = setupPeerConnectionForGroup(participantId)
            if(!peerConnection){
              return 
            }
            const offer = await peerConnection.createOffer();
            await peerConnection.setLocalDescription(offer);
            setincallingroom(true);
            setCallReceiver(false)
            offerArray.current.push({to:participantId,offer:offer})
        
            // socketRef.current.emit("offer-for-group-call", {
            //   offer: peerConnection.localDescription,
            //   chatRoomId: chatRoomid.current,
            //   userId: userid.current,
            //   callType: isAudioCall ? "AUDIO" : "VIDEO",
            //   to:participantId,
            //   createdAt: new Date().getTime(),
            //   participants:participants,
              
            // });
            setCallStarted(true); // Set call as started
            isGroupCall.current = true
          
        }
      }));


       socketRef.current.emit("start-group-call", {
              offers: offerArray.current,
              chatRoomId: chatRoomid.current,
              userId: userid.current,
              callType: isAudioCall ? "AUDIO" : "VIDEO",
              createdAt: new Date().getTime(),
              participants:participants,
              
            },(response) => {
              console.log("response: ", response);
              if (response.error) {
                console.error("Validation failed:", response.error);
              } else {
                setCallId(response.callId)
              }
            });
      // const peerConnection =  setupPeerConnection();
      // const offer = await peerConnection.createOffer();
      // await peerConnection.setLocalDescription(offer);
      // setincallingroom(true);
      // // Emit the offer event to the backend
      // setCallReceiver(false)
      // socketRef.current.emit("offer", {
      //   offer: peerConnection.localDescription,
      //   chatRoomId: chatRoomid.current,
      //   userId: userid.current,
      //   callType: isAudioCall ? "AUDIO" : "VIDEO",
      // });
      // setCallStarted(true); // Set call as started
    }
    if(isCallOpen && amIStartGroupCall.current == true){
      callFunction()
    }
  },[isCallOpen,amIStartGroupCall])


  // useEffect( ()=>{
  //   // console.log("we are in pp peerConnections",peerConnections.current);
  //   let callFunction = async ()=>{
  //     for (const Ids in peerConnections.current) {
  //       const peerConnection =  peerConnections.current[Ids]
  //       if(!peerConnection){
  //         return 
  //       }
  //       const offer = await peerConnection.createOffer();
  //       await peerConnection.setLocalDescription(offer);
  //       setincallingroom(true);
  //       setCallReceiver(false)
    
  //       socketRef.current.emit("offer-for-group-call", {
  //         offer: peerConnection.localDescription,
  //         chatRoomId: chatRoomid.current,
  //         userId: userid.current,
  //         callType: isAudioCall ? "AUDIO" : "VIDEO",
  //         to:Ids,
  //         createdAt: new Date().getTime(),
  //         participants:participants
  //       });
  //       setCallStarted(true); // Set call as started
  //     }
  //   }
  //   if(isCallOpen && amIStartGroupCall.current == true){
  //     callFunction()
  //   }
  // },[amIStartGroupCall.current])



  useEffect(() => {
    // Listen for connection state changes
    const handleConnectionStateChange = () => {
   if(peerConnectionRef.current){
     if (peerConnectionRef.current.connectionState === "connected") {
        console.log("Connected! Starting timer...");
       
        setCallStartTime(Date.now());
      }
    }
    };
    
   if(peerConnectionRef.current){
    peerConnectionRef.current.addEventListener(
      "connectionstatechange",
      handleConnectionStateChange
    );
  }
    // Clean up the event listener
    return () => {
   if(peerConnectionRef.current){
      peerConnectionRef.current.removeEventListener(
        "connectionstatechange",
        handleConnectionStateChange
      );
    }
    };
  }, [peerConnectionRef.current]);



  // const toggleMute = () => {
  //   if (localStream) {
  //     // console.log('lcoalstream',isMuted)
  //     localStream.getAudioTracks()[0].enabled = isMuted;
  //     setIsMuted(!isMuted);
  //   }
  // };
  const canvas = document.createElement('canvas');
  canvas.width = 640; // Set dimensions of the canvas to desired video resolution
  canvas.height = 480;
  const context = canvas.getContext('2d');
  context.fillStyle = 'black';
  context.fillRect(0, 0, canvas.width, canvas.height);
  
  const blankVideoStream = canvas.captureStream();
  const blankVideoTrack = blankVideoStream.getVideoTracks()[0];
  
  // Create a blank (muted) audio stream
  const blankAudioContext = new AudioContext();
  const blankAudioDestination = blankAudioContext.createMediaStreamDestination();
  
  // No need to add an oscillator if you want absolute silence
  const blankAudioTrack = blankAudioDestination.stream.getAudioTracks()[0];
  
  // Combine video and audio into one MediaStream
  const blankStream = new MediaStream([blankVideoTrack, blankAudioTrack]);

  // const toggleVideo = () => {
  //   if (localStream) {
  //     const videoTrack = localStream.getVideoTracks()[0];
  //     const videoSender = peerConnectionRef.current.getSenders().find(sender => sender.track && sender.track.kind === "video");
  
  //     if (isVideoOff) {
  //       // Turn video back on
  //       videoSender.replaceTrack(videoTrack); // Replace with original video track
  //       videoTrack.enabled = true; // Ensure track is enabled
  //     } else {
  //       // Turn video off
  //       videoSender.replaceTrack(blankVideoTrack); // Replace with blank track
  //       videoTrack.enabled = false; // Disable the video track
  //     }
      
  //     setIsVideoOff(!isVideoOff); // Update UI state
  //   }
  // };


  const toggleVideo = () => {

    if(isGroupCall.current){
      participants.map( (item)=>{          
         

        if (localStream) {
          const videoTrack = localStream.getVideoTracks()[0];
          let connection = peerConnections.current[item]
          if(connection){
          const videoSender = connection
            .getSenders()
            .find(sender => sender.track && sender.track.kind === "video");
      
          if (isVideoOff) {
            // Turn video back on
            console.log("Turn video back on: ");
            videoSender.replaceTrack(videoTrack);
            videoTrack.enabled = true;
          } else {
            // Turn video off
            console.log("Turn video off: ");
            videoSender.replaceTrack(blankVideoTrack);
            videoTrack.enabled = false;
          }
      }
          
        }

      })

      setIsVideoOff(!isVideoOff);
    }else{

    if (localStream) {
      const videoTrack = localStream.getVideoTracks()[0];
      const videoSender = peerConnectionRef.current
        .getSenders()
        .find(sender => sender.track && sender.track.kind === "video");
  
      if (isVideoOff) {
        // Turn video back on
        videoSender.replaceTrack(videoTrack);
        videoTrack.enabled = true;
      } else {
        // Turn video off
        console.log("Turn video off: ");
        videoSender.replaceTrack(blankVideoTrack);
        videoTrack.enabled = false;
      }
  
      setIsVideoOff(!isVideoOff);
    }
  }
  };
  
  
 
  // Function to toggle audio
const toggleMute = () => {


  if(isGroupCall.current){
    participants.map( (item)=>{          
       

      if (localStream) {
        const audioTrack = localStream.getAudioTracks()[0];
        let connection = peerConnections.current[item]
        if(connection){
        const audioSender = connection
          .getSenders()
          .find(sender => sender.track && sender.track.kind === "audio");
    
        if (isMuted) {
          // Turn video back on
          audioSender.replaceTrack(audioTrack);
          audioTrack.enabled = true;
        } else {
          // Turn video off
          console.log("Turn video off: ");
          audioSender.replaceTrack(blankAudioTrack);
          audioTrack.enabled = false;
        }
    }
        
      }

    })

    setIsMuted(!isMuted);
  }else{

    if (localStream) {
      const audioTrack = localStream.getAudioTracks()[0];
      const audioSender = peerConnectionRef.current
        .getSenders()
        .find(sender => sender.track && sender.track.kind === "audio");
  
      if (isMuted) {
        // Turn audio back on
        audioSender.replaceTrack(audioTrack);
        audioTrack.enabled = true;
      } else {
        // Turn audio off
        audioSender.replaceTrack(blankAudioTrack);
        audioTrack.enabled = false;
      }
  
      setIsMuted(!isMuted);
    }
}




  
 
};
  
  
const cleanupPeerConnection = (peerConnection) => {
  if (peerConnection) {
    peerConnection.ontrack = null;
    peerConnection.onicecandidate = null;
    peerConnection.oniceconnectionstatechange = null;
    peerConnection.close();
  }
};


  const endCall =async () => {
   
    if (localStream) {
      localStream.getTracks().forEach((track) => track.stop());
    }

    Object.values(peerConnections.current).forEach((peerConnection) => {
      cleanupPeerConnection(peerConnection);
    })

    mediaStreams.map((stream) => 
      Promise.all(stream.getTracks().map((track) => track.stop()))
    )
    if (localVideoRef.current) localVideoRef.current.srcObject = null;
    if (localAudioRef.current) localAudioRef.current.srcObject = null;
    // if (localStream) {
    
    //   localStream?.getTracks()?.forEach(track => track.stop());
    // } // Stop local media tracks
    console.log("Stop local media tracks: ");

    setActiveUsers([])
    setLocalStream(null);
    setRemoteStream(null);
    setCallStarted(false);
    setIsRinging(false);
    setIsCallOpen(false)
    setVideoCall(false)
    setIsMuted(false)
    setIsVideoOff(false)
    setCallStarted(false)
    setIsAudioCall(false)
    setCallId(null)
    setCallReceiver(false)
    setincallingroom(false)
    setCallStartTime(null)
    setIncomingCallerName('')
    setGroupUserName('')
    setRemoteStreams({})
    setTimerUpdate(false)
    setCallReceiverBusy(false)
    setIsRemoteVideoOff(false)
    setIsVideoOff(false)
    setRemoteGroupVideoOff({})
    // navigator.mediaDevices.getUserMedia({ video: true, audio: false })
    // .then(stream => {
    //   stream.getTracks().forEach(track => track.stop()); // Immediately stop the new stream
    // });
   
    setMediaStreams([]);
    setGroupMembersForCall([])
    peerConnections.current={}
    allOffers.current={}
    isGroupCall.current=false
    iceCandidateQueues.current={}
    videoRefs.current=[]
    localVideoRef.current=null
    if (remoteVideoRef) {
      remoteVideoRef.srcObject = null;
    }

    if (peerConnectionRef.current) {
      peerConnectionRef.current.close();
      peerConnectionRef.current = null;
    }

    peerConnectionRef.ontrack = null;
    peerConnectionRef.onicecandidate = null;

    isCallOpenRef.current=false
    remoteVideoRef.current=null
    peerConnectionRef.current=null
    iceCandidateQueue.current=[]
    amICalling.current=null
    chatRoomid.current=null
    amIStartGroupCall.current=false
    remoteAudioRef.current=null
    localAudioRef.current=null
    allIceCandidateQueues.current={}
    offerArray.current=[]
  };

  const existGroupCall = async (data) => {
    const { userId } = data;
    setRemoteStreams((prevStreams) => {
        const newStreams = Object.fromEntries(
            Object.entries(prevStreams).filter(
                ([key, value]) => key !== userId
            )
        );
        return newStreams;
    });

     // 2. Remove the peer connection for the userId from peerConnections
  if (peerConnections.current[userId]) {
    // Close the peer connection
    peerConnections.current[userId].close();
    
    // Remove it from the peerConnections object
    delete peerConnections.current[userId];
  }
};

  const callUtils = {
    configuration,
    getUserMedia,
    setupPeerConnection,
    handleIncomingCall,
    handleIncomingGroupCall,
    acceptCall,
    rejectCall,
    handleAnswer,
    handleICECandidate,
    startCall,
    toggleMute,
    toggleVideo,
    endCall,
    userid,
    chatRoomid,
    socketRef,
    startGroupCall,
    handleICECandidateGroup,
    handleAnswerGroup,
    setParticipants,
    existGroupCall,
    handleReAnswer,
    handleOnTrack,
    allIceCandidateQueues,
    isCallOpenRef
  };

  // Bundle states and utility functions for VideoChat
  const contextValue = {
    localStream,
    setLocalStream,
    remoteStream,
    setRemoteStream,
    isMuted,
    setIsMuted,
    isVideoOff,
    setIsVideoOff,
    callStarted,
    setCallStarted,
    isRinging,
    setIsRinging,
    isAudioCall,
    setIsAudioCall,
    callId,
    setCallId,
    localVideoRef,
    remoteVideoRef,
    peerConnectionRef,
    iceCandidateQueue,
    setIsCallOpen,
    isCallOpen,
    callReceiver,
    incallingroom,
    allOffers,
    setParticipants,
    isGroupCall,
    CallStartTime,
    videoCall,
    setVideoCall,
    userName,
    setUserName,
    incomingCallerName,
    amICalling,
    amIStartGroupCall,
    callUtils,
    videoRefs,
    remoteStreams,
    beforeacceptingcall,
    setGroupUserName,
    localAudioRef,
    remoteAudioRef,
    mediaStreams,
    setGroupMembersForCall,
    groupMembersForCall,
    groupUserName,
    myAllOffers,
    activeUsers,
    setActiveUsers,
    inactivityTimers,
    setCallReceiverBusy,
    callReceiverBusy,
    isRemoteVideoOff,
    setIsRemoteVideoOff,
    remoteGroupVideoOff,
    incomingCallerPhoto,
    userNameProfile,
    setUserNameProfile
  };

  return (
    <VideoChatContext.Provider value={contextValue}>
      {children}
    </VideoChatContext.Provider>
  );
};

import React, { useEffect, useState,useRef } from 'react';
import { Box, IconButton, Typography, TextField, Avatar, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { useTranslation } from "react-i18next";
import axios from 'axios'
import {toast} from "react-hot-toast";
import { ApiConfig } from '../config/apiconfig';

const NewGroupPage = ({ setGroupCreated,appKey,onBack, AppTheme, selectedUserIds,socketRef,userId,groupSelected,showSelectedMember }) => {
  const [groupName, setGroupName] = useState('');
  const [groupIcon, setGroupIcon] = useState(null);
  const { t, i18n } = useTranslation();
  const profileImgUrl=useRef(null);
  const [profileHandling, setProfileHandling] = useState(false);
  const isGroupCreated = useRef(false)

  


  

  let newuserObjects=useRef(null);
  // useEffect(()=>{
   

  //  if(socketRef.current)
  //    socketRef.current.on('groupChatRoomInitiated',(data)=>{
  //   console.log("data: fdgfgd", data);
  //     groupSelected(data)
  // })
  // return () => {
  //   socketRef.current.off("groupChatRoomInitiated");   
    
  // };
  // },[])


  const isTypingInArabic = (text) => {
    if (text === "") return true;
    // Regex to detect Arabic characters including extended ranges
    const arabicRegex =
      /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB70-\uFBFF\uFE70-\uFEFF]/;
    return arabicRegex.test(text);
  };


  




  const handleImageUpload = async (e) => {
    if (!profileHandling) {
      const file = e.target?.files[0];
      setProfileHandling(true);
  
      const formData = new FormData();
      const id = toast.loading(t("uploading_media"));
      formData.append("file", file);
      
      try {
        const response = await axios({
          method: "POST",
          url:ApiConfig.imageUploadURL,
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
  
        if (response && response?.data?.responseCode === 200) {
          profileImgUrl.current = response?.data?.result;
          setGroupIcon(profileImgUrl.current);
          toast.success(t("media_uploaded_successfully"), { id });
        } else {
          toast.error(
            response?.data?.responseMessage || t("unable_to_upload_media"),
            { id }
          );
        }
        
      } catch (err) {
        toast.error(
          err?.response?.data?.responseMessage ||  t("error_occurred_during_upload"),
          { id }
        );
      } finally {
        
        setProfileHandling(false);
      }
    }
  };




  return (
    <Box
      sx={{
      
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', // Center align all elements
        boxSizing: 'border-box',
        position: 'absolute',
        padding: '15px 12px 12px 12px',
        width: '100%',
        height: '100%',
        backgroundColor: AppTheme === 'dark' ? '#111b21' : '#ffffff',
        zIndex: 9999,
      }}
    >
      {/* Header */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          maxWidth: 400, // Maintain the same maxWidth for alignment
        }}
      >
        <IconButton onClick={onBack} sx={{ color: "#8696a0" }}>
          <ArrowBackIcon />
        </IconButton>
        <Typography sx={{ fontSize: '16px', marginLeft: 1, color: AppTheme === 'dark' ? '#e9edef' : '#111B21' }}>
        {t("new_group")}
        </Typography>
      </Box>

      {/* Group Icon Section */}
      <Box
        mt={4}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 1,
        }}
      >
       {!profileHandling && <input
          accept="image/*"
          id="group-icon-upload"
          type="file"
          style={{ display: 'none' }}
          onChange={handleImageUpload}
        />}
        <label htmlFor="group-icon-upload">
          <Avatar
            src={groupIcon}
            sx={{
              width: 150,
              height: 150,
              backgroundColor: '#e0e0e0',
              cursor: 'pointer',
            }}
          >
            {!groupIcon && <GroupAddIcon sx={{ fontSize: 48, color: '#aaa' }} />}
          </Avatar>
        </label>
        <Typography sx={{ color: AppTheme === 'dark' ? '#e9edef' : '#111B21', fontSize: '13px' }}> {t("Tap_to_add_group_icon")}</Typography>
      </Box>

      {/* Group Name Text Field */}
      <Box mt={2} sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <TextField
        multiline
          variant="standard"
          fullWidth
          label={t("groupName")}
          value={groupName}   
          onChange={(e) => setGroupName(e.target.value)}
          sx={{
            textAlign:
            i18n.language === "ar" && isTypingInArabic(groupName)
              ? "right"
              : "left", // Align text based on language
          direction:
            i18n.language === "ar" && isTypingInArabic(groupName)
              ? "rtl"
              : "ltr",
            maxWidth: 300, // Set the same maxWidth for consistency
            '& .MuiInput-underline:before': {
              borderBottomColor: AppTheme === 'dark' ? 'rgba(192, 192, 192, 0.623)' : '#f0f2f5',
              borderBottomWidth: '2px',
            },
            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
              borderBottomColor: '#00A884'
            },
            '& .MuiInput-underline:after': {
              borderBottomColor: '#00A884'
            },
            '& .MuiInputLabel-root': {
              color: '#8696a0',
              fontSize: '14px',
              fontWeight: 600,
            },
            '& .MuiInputBase-root': {
              color: AppTheme === 'dark' ? '#e9edef' : '#111B21',
            },
          }}
          inputProps={{
            maxLength: 100, // Set the maximum length of the input
          }}
        />
      </Box>

      {/* Save Button */}
      <Box mt={3} sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <Button
          sx={{
            backgroundColor: '#00A884',
            color: 'white', // Text color
            '&:hover': {
              backgroundColor: '#00A884',
            },
            borderRadius: '15px',
            padding: '6px 24px', // Adjust padding for consistency
          }}
          onClick={() => {
            // Handle save group functionality
          
            // console.log('Group Name:', groupName);
            // console.log('Group Icon:', groupIcon);
           
            showSelectedMember()
            const userObjects = selectedUserIds.map(userId => ({
              userId: userId,
              userRole: "USER" // Assuming userRole is the same for all
          }))
          newuserObjects.current= [ {
            "userId": userId,
            "userRole": "ADMIN"
        }, ...userObjects]

            let roomData={
              "appKey":appKey,
              "groupName":groupName.trim(),
              'imageUrl':profileImgUrl.current,
              'userDetails':newuserObjects.current
            }
            socketRef.current.emit('initiateGroupChatRoom',roomData,(response) => {
              if(response.success){
                // showSelectedMember()
                
              }
              if (response.error) {
                if(response.success===false){
                  toast.error(response.message)
                  
                }
                console.error("Validation failed:", response.error);
              } else {
                if(response.success===false){
                  toast.error(response.message)
                }
                // console.log("User validated:", response);
              }
            })
            isGroupCreated.current = true
          }}
          disabled={!groupName.trim() || isGroupCreated.current}
        >
           {t("save")}
        </Button>
      </Box>
    </Box>
  );
};

export default NewGroupPage;

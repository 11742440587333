import React, { useState, useEffect } from 'react';
import './AudioRoom.css';
import { useVideoChat } from "./Context/VideoChatContext";

const AudioRoom = ({ streamsArray, groupMembersForCall }) => {
  
  const {
    activeUsers,
    setActiveUsers,
    callUtils,
    inactivityTimers
  } = useVideoChat();




  return (
    <div className={`audio-room user-count-${Object.keys(streamsArray).length}`}>
      {streamsArray && Object.entries(streamsArray).map(([key, stream]) => {
        const isActive = activeUsers.includes(key); // Check if the current user is active
        const user = groupMembersForCall?.find((item) => item.userId === key);
        return (
          <div
            key={key}
            className={`thumbnailContainer ${isActive ? 'highlight' : ''}`}
            // onClick={() => {
            //   setSelectedStream(stream);
            //   const user = groupMembersForCall?.find(item => item.userId === key);
            //   if (user) setCallerNameInGroup(user.userName);
            // }}
          >
            <audio
              autoPlay
              ref={(el) => {
                if (el && el.srcObject !== stream) el.srcObject = stream;
              }}
              className="audioStream"
            />
            <div className="avatarContainer"   style={{
                 
                  height: "100%",
                  width: "100%", // Make it overlay the entire container
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
            
                }}>
                  {/* Profile Image */}
                  <div className="avatar">
                    {user?.profileUrl ? (
                      <img src={user.profileUrl} alt={`${user?.userName}'s avatar`} className="avatarImage" />
                    ) : (
                      <span className="avatarPlaceholder">{user?.userName?.charAt(0)}</span>
                    )}
                  </div>
                  {/* Display User Name */}
                  <div className="userName">{user?.userName}</div>
                </div>
            {/* <div className="userName">{groupMembersForCall.find(item => item.userId === key)?.userName}</div> */}
            {isActive && <div className="audio-indicator" />} {/* Indicator if active */}
          </div>
        );
      })}
    </div>
  );
};

export default AudioRoom;

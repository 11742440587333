import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  Box,
  Grid,
  TextField,
  Typography,
  Avatar,
  useMediaQuery,
  useTheme,
  styled,
  InputAdornment,
  Badge,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Fab,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import "../App.css";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { MdSignalWifiOff } from "react-icons/md";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import VideocamIcon from "@mui/icons-material/Videocam";
import MicIcon from "@mui/icons-material/Mic";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import WhatsAppClockIcon from "./WhatsAppClockIcon";

import ChatScreen from "./Chatting_screen";
import SearchIcon from "@mui/icons-material/Search";

import io from "socket.io-client";
import { useParams } from "react-router-dom";
import { format, isToday, isThisWeek, isYesterday } from "date-fns"; // Importing date-fns for formatting time
import { hi, enUS, ar } from "date-fns/locale";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

import DoneAllIcon from "@mui/icons-material/DoneAll";
import DoneIcon from "@mui/icons-material/Done";
import AddIcon from "@mui/icons-material/Add";

import { useTranslation } from "react-i18next";
import {
  openDB,
  addMessageToDB,
  getMessagesFromDB,
  deleteMessageFromDB,
} from "../utility/indexedDB";
import AddGroupMembersScreen from "./AddGroupMembersScreen";
import GroupInfoPage from "./GroupInfoPage ";
import { LocalConvenienceStoreOutlined } from "@mui/icons-material";
import { useVideoChat } from "./Context/VideoChatContext";
import VideoChat from "./VideoChat";
import { socket } from "./../config/apiconfig";

const ChatContainer = styled(Box)(({ theme }) => ({
  // padding: "0 20px",
  backgroundColor: "#f5f5f5",
  // borderRadius: "15px",
  height: "100vh",
  overflow: "hidden",

  "& .MuiOutlinedInput-root": {
    borderRadius: "8px", // Customize the border radius
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    height: "35px",
    paddingLeft: "10px",
    //   backgroundColor: '#202c33',
    color: "#8696a0",
    border: "none",
  },

  "& .MuiOutlinedInput-notchedOutline": {
    border: "none", // Remove the border
  },

  // Styling for the placeholder
  "& .MuiInputBase-input::placeholder": {
    color: "#8696a0", // Placeholder color
    opacity: 1, // Ensure the color applies fully
  },

  "& .imgbox": {
    display: "flex",
    gap: "1rem",
    alignItems: "center",
    padding: "10px",
  },
  "& .singleMsg": {
    padding: "21px 20px 22px 23px",
  },
  "& .upperChat": {
    padding: "14px 27px 0 22px",
  },
  "& .displaySpacebetween": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down("md")]: {
      gap: "5px",
    },
    "& img": {
      width: "60px",
      height: "60px",
    },
  },
}));

const MessageScreen = ({ language, appId, appKey, usersDetails }) => {
  const [isGroupScreenVisible, setIsGroupScreenVisible] = useState(false);
  const [groupInfoPage, setgroupInfoPage] = useState(false);

  const [messages, setMessages] = useState([]);
  const [chatData, setChatData] = useState([]);
  const socketRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedChat, setSelectedChat] = useState(null);
  const [selectedRoomId, setSelectedRoomId] = useState(null);
  const [showChat, setShowChat] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [receiverName, setReceiverName] = useState("");
  const [receiverId, setReceiverId] = useState("");
  const [groupIcon, setGroupIcon] = useState(null);
  const [newGroupName, setNewGroupName] = useState();
  const [members, setGroupMembers] = useState([]);
  const [groupAdminName, setGroupAdminName] = useState();
  const [groupCreationDate, setgroupCreationDate] = useState("");
  const [isChatListLoading, setChatListLoading] = useState(true);
  const [groupCreated, setGroupCreated] = useState(false);

  const [userId, setUserId] = useState(
    usersDetails.senderDetails.userId
  );
  // const [userId, setUserId] = useState(useParams().id);
  const [typingStatus, setTypingStatus] = useState(false);
  const [receiverDetails, setReceiverDetails] = useState("");
  const theme = useTheme();
  const [AppTheme, setAppTheme] = useState("light");
  const [themeDialogOpen, setThemeDialogOpen] = useState(false);
  const [connect, setConnect] = useState(true);
  const isSmScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isXsScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md")); // Changed breakpoint to "md" for a wider large screen
  const ismdScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTheme, setSelectedTheme] = useState(AppTheme); // Default theme
  const [fontSizeDialogOpen, setFontSizeDialogOpen] = useState(false);
  const [fontSize, setFontSize] = useState("medium");
  const [selectedFontSize, setSelectedFontSize] = useState(fontSize);
  const msgRef = useRef(false);
  const [onlineMode, setOnlineMode] = useState(true);
  const userChatRef = useRef(false);
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const chatRefs = useRef(null);
  const update = useRef(true);
  const [profileImg, setProfileImg] = useState("");
  const [roomInitiateChat, setRoomInitiateChat] = useState(true);
  const [languageDialogOpen, setLanguageDialogOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("en"); // Default language
  const [chatType, setchatType] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [participants, setparticipants] = useState([]);

  const socketUrl = useMemo(() => socket, []);

  const [mediaFileInput, setMediaFileInput] = useState(null);
  const [isCameraOn, setIsCameraOn] = useState(false);
  const [caption, setCaption] = useState("");
  const [changeUser, setChangeUser] = useState(false);
  const [groupUsers, setGroupUsers] = useState([]);
  const [typingInGroup, setTypingInGroup] = useState("");
  const [userTyping, setUserTyping] = useState([]);
  const newGroupId = useRef(null);
  const isIncomingCall = useRef(false);
  const [callingData, setCallingData] = useState(null);

  const {
    localStream,
    setLocalStream,
    remoteStream,
    setRemoteStream,
    isMuted,
    setIsMuted,
    isVideoOff,
    setIsVideoOff,
    callStarted,
    setCallStarted,
    isRinging,
    setIsRinging,
    isAudioCall,
    setIsAudioCall,
    callId,
    setCallId,
    localVideoRef,
    remoteVideoRef,
    peerConnectionRef,
    iceCandidateQueue,
    setIsCallOpen,
    isCallOpen,
    callReceiver,
    setUserName,
    amICalling,
    amIStartGroupCall,
    incallingroom,
    allOffers,
    myAllOffers,
    setParticipants,
    isGroupCall,
    callUtils,
    setCallReceiverBusy,
    setUserNameProfile
  } = useVideoChat();

  useEffect(() => {
    callUtils.userid.current = userId;

    callUtils.socketRef.current = socketRef.current;
  }, [userId, socketRef.current]);
  const socketEvent = {
    initiateChat: "initiateUserChat",
    sendMessage: "userSendMessage",
    receiveMessage: "receiveUserMessage",
    userMessage: "getUserMessage",
  };
  const dbName = "ChatAppDB";
  const storeName = "unsentMessages";
  const db = useRef(null);

  useEffect(() => {
    if (!socketRef.current) {
      socketRef.current = io(socketUrl, {
        transports: ["websocket"],
        secure: true,
        reconnect: true,
        reconnectionAttempts: 10, // Number of attempts to reconnect
        reconnectionDelay: 1000, // Delay between attempts
      });
    }

    const socket = socketRef.current;

    socket.on("connect", () => {
      console.log("Socket connected");
      // console.log("Socket connected: ", socket);
      initiateChat();
      // validateUserApp();

      if (!db.current) {
        openDB(dbName, storeName)
          .then((database) => {
            db.current = database;
          })
          .catch((error) => {
            console.error("Error opening database:", error);
          });
      }
    });

    socket.on("disconnect", () => {
      console.log("Socket disconnected");
    });

    socket.on("reconnect", (attempt) => {
      console.log(`Socket reconnected after ${attempt} attempts`);
    });

    socket.on("userChatList", (data) => {
      // console.log("newGroup: ", newGroupId.current);
      setChatListLoading(false);
      if (update.current) {
        setChatData(data.userChatList);
        setUserName(data.userName);
        setUserNameProfile(data.userProfile)
        update.current = false;
      } else if (newGroupId.current) {
        let newGroup = data.userChatList.find(
          (item) => item.chatRoomId == newGroupId.current
        );
        // console.log("data.userChatList: ", data.userChatList);
        if (newGroup) {
          setChatData((preData) => [newGroup, ...preData]);
        }
        newGroupId.current = null;
      }
    });

    socket.on("userChatInitiated", (data) => {
      // console.log("romminndfnsdfnsd", roomInitiateChat);
      if (roomInitiateChat == true && chatRefs.current != true)
        if (roomInitiateChat == true && chatRefs.current != true)
          // console.log("romminndfnsdfnsd", roomInitiateChat);
          setRoomInitiateChat(data.chatRoomId);

      validateUserApp();
    });

    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("reconnect");
      socket.off("userChatList");
      socket.off("userChatInitiated");
      disconnectSocket();
    };
  }, []);

  useEffect(() => {
    setTypingInGroup("");
    const groupData = chatData.filter(
      (item) => item.chatRoomId === selectedRoomId && item.chatType === "GROUP"
    );

    if (groupData.length > 0) {
      let users =
        groupData[0]?.groupDetails?.allUserDetails?.map((item) =>
          item.userId === userId ? "You" : item.userName
        ) || [];
      let Participants =
        groupData[0]?.groupDetails?.allUserDetails?.map(
          (item) => item.userId
        ) || [];

      console.log("Participants: ", Participants);
      callUtils.setParticipants(Participants);

      users.sort((a, b) => a.localeCompare(b));

      setGroupUsers(users);
    }
  }, [selectedRoomId]);

  // const handleIncomingCall = (data) => {
  //   const { offer, callId: incomingCallId } = data;
  //   console.log("we are in the ringing data ......");
  //   setIsRinging(true);
  //   window.incomingOffer = offer; // Save the incoming offer
  //   setCallId(incomingCallId); // Set call ID for later use
  // };
  // useEffect(() => {

  //   if (socket) {
  //     socket.on('offer', handleIncomingCall);
  //     socket.on('callRejected', () => setIsRinging(false));
  //   }
  //   return () => {
  //     if (socket) {
  //       socket.off('offer', handleIncomingCall);
  //       socket.off('callRejected'); // Cleanup on unmount
  //     }
  //   };
  // }, []);

  async function moveIdFirst(data, unreadMessagesCount = false) {
    try {
      let itemToMove = chatData.find(
        (item) => item.chatRoomId === data.chatRoomId
      );
      const remainingItems = chatData.filter(
        (item) => item.chatRoomId !== data.chatRoomId
      );
      itemToMove.lastMessageDetails = data;
      setChatData(itemToMove ? [itemToMove, ...remainingItems] : chatData);
    } catch (error) {}
  }

  useEffect(() => {
    if (roomInitiateChat && chatData.length > 0) {
      console.log("selectedjkldsjfkjsdfk", roomInitiateChat);
      const selectedChat = chatData.filter(
        (chat) => chat.chatRoomId === roomInitiateChat
      );

      setRoomInitiateChat(null);
      console.log("jiortweiofnjkasdiopj", selectedChat);
      setSelectedRoomId(selectedChat[0]?.chatRoomId);
      setShowChat(true);
      setReceiverName(selectedChat[0]?.userDetails?.userName);
      setReceiverId(selectedChat[0]?.userDetails?.userId);
      setReceiverDetails(selectedChat[0]?.userDetails);
      setProfileImg(selectedChat[0]?.userDetails?.profileUrl);
      setchatType(selectedChat[0]?.chatType);
    }
  }, [chatData]);

  const initiateChat = () => {
    if (!socketRef.current) {
      console.error("Socket connection not established");
      return;
    }

    const roomData = {
      appKey: appKey,
      ...usersDetails,
    };

    socketRef.current.emit("initiateUserChat", roomData);
  };

  const validateUserApp = () => {
    if (!socketRef.current) {
      console.error("Socket connection not established");
      return;
    }
    // console.log("validated app");
    const roomData = {
      appKey: appKey,
      userId: userId,
      appId: appId,
    };

    socketRef.current.emit("validateUserApp", roomData, (response) => {
      if (response.error) {
        console.error("Validation failed:", response.error);
      } else {
        sendMessageOffline();
        // console.log("User validated:", response);
      }
    });
  };

  const disconnectSocket = () => {
    if (socketRef.current) {
      socketRef.current.off("connect");

      socketRef.current.off(socketEvent.receiveMessage);

      socketRef.current.disconnect();
      socketRef.current = null;
      console.log("Socket disconnected");
    }
  };

  const handleChatItemClick = (selectedChat) => {
    if ((isGroupCall.current && !isRinging) || amICalling.current == true || amIStartGroupCall.current==true || (callReceiver && !incallingroom)) return;
    selectedRoomId !== selectedChat?.userDetails?.chatRoomId &&
      setChangeUser(true);
    // setMessages([])
    setSelectedRoomId(selectedChat?.chatRoomId);
    setShowChat(true);
    selectedChat.chatType === "ONE_TO_ONE"
      ? setReceiverName(selectedChat?.userDetails?.userName)
      : setReceiverName(selectedChat?.groupDetails?.groupName);
    selectedChat.chatType === "ONE_TO_ONE" &&
      setReceiverId(selectedChat?.userDetails?.userId);
    selectedChat.chatType === "ONE_TO_ONE" &&
      setReceiverDetails(selectedChat?.userDetails);
    selectedChat.chatType === "ONE_TO_ONE"
      ? setProfileImg(selectedChat?.userDetails?.profileUrl)
      : setProfileImg(selectedChat?.groupDetails?.imageUrl);
    // console.log(
    //   "selectedChat?.chatTypeselectedChat?.chatType",
    //   selectedChat?.chatType
    // );
    setchatType(selectedChat?.chatType);
    selectedChat.chatType !== "ONE_TO_ONE" &&
      setparticipants(selectedChat.groupDetails.allUserDetails);
    setGroupIcon(null);
    setNewGroupName();
    setGroupMembers([]);
    // console.log("selectedChat: ", selectedChat);
    if (selectedChat && selectedChat.chatType !== "ONE_TO_ONE") {
      // console.log("selectedChat: sdfdsf", selectedChat.groupCreatedBy);
      if (userId == selectedChat?.groupCreatedBy?.userId) {
        setGroupAdminName("");
        setgroupCreationDate(selectedChat?.groupCreatedBy?.createdAt);
      } else {
        setGroupAdminName(selectedChat?.groupCreatedBy?.userName);
        setgroupCreationDate(selectedChat?.groupCreatedBy?.createdAt);
      }
    }
  };

  const groupSelected = (selectedChat) => {
    setSelectedRoomId(selectedChat?.chatRoomId);
    setShowChat(true);
    setReceiverName(selectedChat.groupName);
    setReceiverId("");
    setReceiverDetails("");
    setProfileImg(selectedChat?.imageUrl);
    setIsGroupScreenVisible(false);
    setchatType("GROUP");
    setparticipants(selectedChat.participants);
  };
  const getHindiTimePeriod = (hours) => {
    if (hours >= 5 && hours < 12) {
      return "सुबह"; // Morning
    } else if (hours >= 12 && hours < 17) {
      return "दोपहर"; // Afternoon
    } else if (hours >= 17 && hours < 20) {
      return "शाम"; // Evening
    } else {
      return "रात"; // Night
    }
  };

  const convertToArabicNumerals = (number) => {
    const arabicDigits = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
    return number
      .toString()

      .split("")
      .map((digit) => (/\d/.test(digit) ? arabicDigits[digit] : digit))
      .join("");
  };

  const getArabicTimePeriod = (hours) => {
    if (hours >= 5 && hours < 12) {
      return "صباحًا"; // Morning
    } else if (hours >= 12 && hours < 17) {
      return "ظهرًا"; // Afternoon
    } else if (hours >= 17 && hours < 20) {
      return "مساءً"; // Evening
    } else {
      return "ليلًا"; // Night
    }
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return;
    const date = new Date(timestamp);

    if (isNaN(date)) {
      return "Invalid date";
    }

    // Determine the user's current language
    const currentLanguage = i18n.language || "en"; // Default to 'en' if no language is set

    // Select locale based on the language
    const locale =
      currentLanguage === "hi" ? hi : currentLanguage === "ar" ? ar : enUS;

    const hours = date.getHours();
    const minutes = date.getMinutes();

    if (isToday(date)) {
      // Format time and number localization based on the language
      const formattedTime = format(date, "h:mm", { locale });

      let timePeriod;
      if (currentLanguage === "hi") {
        timePeriod = getHindiTimePeriod(hours);
        return `${timePeriod} ${formattedTime} बजे`;
      } else if (currentLanguage === "ar") {
        timePeriod = getArabicTimePeriod(hours);

        // Convert numbers to Arabic format manually
        const arabicFormattedTime = `${convertToArabicNumerals(
          hours
        )}:${convertToArabicNumerals(minutes)}`;
        return `${arabicFormattedTime} ${timePeriod}`;
      } else {
        timePeriod = format(date, "a", { locale }).toLowerCase();
        return `${formattedTime}${timePeriod}`;
      }
    } else if (isYesterday(date)) {
      // Yesterday: Display appropriate terms in Hindi and Arabic
      return currentLanguage === "hi"
        ? "कल"
        : currentLanguage === "ar"
        ? "أمس"
        : "Yesterday";
    } else if (isThisWeek(date)) {
      // This week: Format as day of the week (e.g., "Monday" or "सोमवार")
      return format(date, "EEEE", { locale });
    } else {
      // Older messages: Format as dd/MM/yyyy and localize numbers in Arabic
      const formattedDate = format(date, "dd/MM/yyyy", { locale });
      if (currentLanguage === "ar") {
        const day = convertToArabicNumerals(date.getDate());
        const month = convertToArabicNumerals(date.getMonth() + 1);
        const year = convertToArabicNumerals(date.getFullYear());
        return `${day}/${month}/${year}`;
      }
      return formattedDate;
    }
  };

  const truncateText = (text, limit) => {
    // if (text.length > limit) {
    //   return text.slice(0, limit) + "...";
    // }
    return text;
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleThemeDialogOpen = () => {
    setThemeDialogOpen(true);
    handleMenuClose();
  };
  const handleThemeDialogClose = (applyTheme = false) => {
    if (applyTheme) {
      setAppTheme(selectedTheme); // Apply the selected theme
    }
    setThemeDialogOpen(false);
  };

  const handleThemeChange = (event) => {
    setSelectedTheme(event.target.value);
  };

  const handleFontSizeDialogOpen = () => {
    setFontSizeDialogOpen(true);
    handleMenuClose();
  };

  const handleFontSizeDialogClose = (applyFontSize = false) => {
    if (applyFontSize) {
      setFontSize(selectedFontSize); // Apply the selected font size
    }
    setFontSizeDialogOpen(false);
  };

  const handleFontSizeChange = (event) => {
    setSelectedFontSize(event.target.value);
  };

  // const handleOnline = async () => {
  //   openDB(dbName, storeName)
  //     .then((database) => {
  //       db.current = database;
  //     })
  //     .catch((error) => {
  //       console.error("Error opening database:", error);
  //     });
  //   console.log("socket checking ", socketRef.current);
  //   console.log("indexedDB connected with you");
  //   if (db.current) {
  //     msgRef.current = true;
  // setMessages((prevMessageList) => {
  //   console.log("msg deleted sir", db);
  //   return prevMessageList.filter((msg) => msg.status !== "unsent");
  // });
  //   }
  // };

  // useEffect(() => {
  //   if (msgRef.current === true) {
  //     msgRef.current = false;

  //     const sendmessages = async () => {
  //       console.log(
  //         "inside sendmessages sir how are you please tell me ",
  //         db.current
  //       );
  //       if (db.current) {
  //         console.log("indexedDB connected with you");
  //         const unsentMessages = await getMessagesFromDB(db.current, storeName);
  //         if (unsentMessages.length > 0) {
  //           const messageToSend = { ...unsentMessages[0] };
  //           delete messageToSend.status;
  //           socketRef.current.emit(socketEvent.sendMessage, messageToSend);
  //           await deleteMessageFromDB(
  //             db.current,
  //             storeName,
  //             unsentMessages[0].id
  //           );
  //         }
  //       }
  //     };
  //     console.log("sendmessges are here sir");
  //     sendmessages();
  //   }
  // }, [messages, msgRef.current]);

  //   useEffect(() => {
  //   if (msgRef.current === true) {
  //     msgRef.current = false;

  //     const sendmessages = async () => {
  //       console.log(
  //         "inside sendmessages sir how are you please tell me ",
  //         db.current
  //       );
  //       if (db.current) {
  //         console.log("indexedDB connected with you");
  //         const unsentMessages = await getMessagesFromDB(db.current, storeName);
  //         if (unsentMessages.length > 0) {
  //           // Emit all unsent messages
  //           for (const message of unsentMessages) {
  //             const messageToSend = { ...message };

  //             socketRef.current.emit(socketEvent.sendMessage, messageToSend);
  //             // Optionally, wait for a response or acknowledgment from the server
  //             // If you need to delete after sending, await the deletion
  //             await deleteMessageFromDB(db.current, storeName, message.id);
  //           }
  //         }
  //       }
  //     };
  //     console.log("sendmessges are here sir");
  //     sendmessages();
  //   }
  // }, [onlineMode]);

  const changeLastMessageStatus = (data, type) => {
    let chatDataArray = chatData.map((item) => {
      if (type == "send" && item.chatRoomId === data.chatRoomId) {
        item.lastMessageDetails = data;
        return item;
      } else if (
        type == "receive" &&
        item.chatRoomId === data.chatRoomId &&
        item.lastMessageDetails._id == data.messageId
      ) {
        item.lastMessageDetails.messageStatus = data.messageStatus;
        return item;
      } else return item;
    });
    if (chatDataArray.length > 0) {
      setChatData(chatDataArray);
    }
    data.chatRoomId === selectedRoomId &&
      setMessages((prevMessageList) =>
        prevMessageList.map((msg) =>
          msg.messageId === data.messageId || msg._id === data.messageId
            ? { ...msg, messageStatus: data.messageStatus }
            : msg
        )
      );
  };

  let isUpdating = false;
  function moveIdFirst(data, unreadMessagesCount = false) {
    isUpdating = true;
    try {
      let itemToMove = chatData.find(
        (item) => item.chatRoomId === data.chatRoomId
      );
      const remainingItems = chatData.filter(
        (item) => item.chatRoomId !== data.chatRoomId
      );
      itemToMove.lastMessageDetails = data;
      if (unreadMessagesCount && selectedRoomId != itemToMove.chatRoomId) {
        itemToMove.unreadMessagesCount = itemToMove.unreadMessagesCount + 1;
        // console.log("itemToMove: ", itemToMove);
      }
      setChatData(itemToMove ? [itemToMove, ...remainingItems] : chatData);
      if (data.chatRoomId === selectedRoomId) {
        setMessages((prevMessageList) => [...prevMessageList, data]);

        socketRef.current.emit("userUpdateMessageStatus", {
          chatRoomId: selectedRoomId,
          userId,
          messageId: data.messageId,
          messageStatus: itemToMove.chatType === "GROUP" ? undefined : "READ",
        });
      }
    } catch (error) {}
    isUpdating = false;
  }

  function changeTypeingStatus(data) {
    if (isUpdating) return;
    try {
      let itemToMove = chatData.map((item) => {
        try {
          if (
            item.chatType == "ONE_TO_ONE" &&
            item.chatRoomId === data.chatRoomId
          ) {
            item.userDetails.isOnline = data.onlineStatus;
            item.userDetails.isTyping = data.typingStatus;
          }
          return item;
        } catch (error) {
          return item;
        }
      });
      if (itemToMove.length > 0) {
        // chatArray = itemToMove
        setChatData(itemToMove);
      }
    } catch (error) {}
  }

  const removeObjectById = (array, id) => {
    return array.filter((item) => item.userId !== id);
  };

  useEffect(() => {
    if (socketRef.current) {
      socketRef?.current.on("groupChatDetails", (data) => {
        // console.log("groupChatDetails: ", data);

        let groupNotExist = true;
        let groupDataId = chatData.find(
          (item) => item?.chatRoomId == data?.groupDetails?._id
        );
        if (groupDataId) {
          groupNotExist = false;
        }
        setChatData((preData) => {
          return preData.map((item) => {
            if (item?.chatRoomId == data?.groupDetails?._id) {
              item.groupDetails.allUserDetails =
                data?.groupDetails?.participants;
              item.groupDetails.groupName = data?.groupDetails?.groupName;
              item.groupDetails.imageUrl = data?.groupDetails?.imageUrl;
              //  groupNotExist=false
            }
            return item;
          });
        });
        //  console.log("groupNotExist: ", groupNotExist);
        if (groupNotExist) {
          newGroupId.current = data?.groupDetails?._id;
          // console.log("newGroupId.current: ", newGroupId.current);
        }
        //
        let groupUserList = data?.groupDetails?.participants?.map((item) => {
          return item.userId == userId ? "You" : item.userName;
        });

        groupUserList = groupUserList.sort((a, b) => a.localeCompare(b));

        setGroupUsers(groupUserList);
        setGroupMembers(data?.groupDetails.participants);
        // setScrollfalse(false);
        if (data?.groupDetails?._id == selectedRoomId) {
          setReceiverName(data?.groupDetails?.groupName);

          setProfileImg(data?.groupDetails?.imageUrl);

          setparticipants(data?.groupDetails.participants);

          setGroupIcon(data?.groupDetails?.imageUrl);
          setNewGroupName(data?.groupDetails?.groupName);
          // setGroupMembers(data?.groupDetails.participants)
        }
      });

      socketRef.current.on("sendersTypingAndOnlineStatus", (data) => {
        if (selectedRoomId == data.chatRoomId) {
          setTypingStatus(data);
        }

        setChatData((preChatData) => {
          return preChatData.map((item) => {
            if (
              item.chatRoomId == data.chatRoomId &&
              item.chatType == "GROUP"
            ) {
              if (data.typingStatus) {
                item.isTyping = data.userName + " is typing";
              } else {
                item.isTyping = "";
              }

              if (item.chatRoomId == selectedRoomId) {
                setTypingInGroup(item.isTyping);
              }
            }
            return item;
          });
        });

        changeTypeingStatus(data);
      });

      socketRef.current.on("receiveUserMessage", (data) => {
        // console.log("data: ", data);
        moveIdFirst(data, true);
      });

      socketRef.current.on("userMessageStatusUpdated", (data) => {
        changeLastMessageStatus(data, "receive");
      });
      socketRef.current.on("userRoleUpdated", (data) => {
        if (selectedRoomId == data.chatRoomId) {
          setGroupMembers(
            members.map((item) => {
              if (data.userId == item.userId) {
                item.userRole = data.userRole;
              }
              return item;
            })
          );
        }
      });
      socketRef.current.on("userRemovedFromGroupChat", (data) => {
        if (selectedRoomId == data.chatRoomId) {
          const updatedArray = removeObjectById(participants, data.userId);
          setparticipants(updatedArray);
        }

        if (userId == data.userId) {
          if (selectedRoomId == data.chatRoomId) {
            setSelectedRoomId(null);
          }
          setChatData((preData) =>
            preData.filter((item) => item.chatRoomId != data.chatRoomId)
          );
          return;
        }
        setGroupMembers((preData) => {
          let newData = preData.filter((item) => item.userId != data.userId);
          return newData;
        });

        const groupData = chatData.filter(
          (item) =>
            item.chatRoomId === selectedRoomId && item.chatType === "GROUP"
        );

        if (groupData.length > 0) {
          let users =
            groupData[0]?.groupDetails?.allUserDetails
              ?.filter((item) => item.userId != data.userId)
              .map((item) =>
                item.userId === userId ? "You" : item.userName
              ) || [];

          users.sort((a, b) => a.localeCompare(b));

          setGroupUsers(users);
        }
        setChatData((preData) => {
          return preData.map((item) => {
            if (
              item.chatRoomId === selectedRoomId &&
              item.chatType === "GROUP"
            ) {
              item.groupDetails.allUserDetails =
                item?.groupDetails?.allUserDetails?.filter(
                  (users) => users.userId != data.userId
                );
            }
            return item;
          });
        });
      });
      socketRef.current.on("groupChatRoomInitiated", (data) => {
        console.log("groupChatRoomInitiated: ", data);
        let groupNotExist = true;
        chatData.map((item) => {
          if (item?.chatRoomId == data?.groupDetails?._id) {
            groupNotExist = false;
          }
        });
        if (groupNotExist) {
          newGroupId.current = data?.chatRoomId;
        }
        console.log("groupChatRoomInitiated", groupNotExist);
        const adminProfile = data.participants.filter(
          (member) => member.userRole === "ADMIN"
        );

        adminProfile[0].userId == userId && groupSelected(data);
      });
      
      socketRef.current.on("offer", (data) => {
        
        // console.log("data: of offer ", data);
        
        console.log("isCallOpen: ", callUtils.isCallOpenRef.current);
        if (callUtils.isCallOpenRef.current) {
          let room = {
            chatRoomId: data.chatRoomId,
            userId: userId,
            callerId: data.userId,
          };
          socketRef.current.emit("callReceiverBusy", room   );
        } else {
          callUtils.handleIncomingCall(data);
        }
      });

      socketRef.current.on("callReceiverBusy", (data) => {
       chatType!=='GROUP' &&  setCallReceiverBusy(true);
      });
      socketRef.current.on("answer", (data) => callUtils.handleAnswer(data));
      socketRef.current.on("join-group-call", (data) => {
        callUtils.handleAnswerGroup(data);
      });
      socketRef.current.on("ice-candidate", callUtils.handleICECandidate);
      socketRef.current.on("ice-candidate-in-group-call", (data) => {
        console.log("data: ", data);
        if (callUtils.isCallOpenRef.current && callUtils.chatRoomid.current!=data.chatRoomId) {
          console.log(data.chatRoomId,'&&&',callUtils.chatRoomid.current,"isCallOpen: inside ice-candidate-in-group-call", isCallOpen);
            return
        }
        console.log(data.chatRoomId,'&&&',callUtils.chatRoomid.current,"isCallOpen: ouside ice-candidate-in-group-call", isCallOpen);
        const { candidate, createdAt, to, from, chatRoomId, userId } = data;
        if (
          data?.offerType == "offer" &&
          myAllOffers.current[chatRoomId] &&
          myAllOffers.current[chatRoomId][from]
        ) {
          if (!callUtils.allIceCandidateQueues.current[from]) {
            callUtils.allIceCandidateQueues.current[from] = [];
          }
          callUtils.allIceCandidateQueues.current[from].push(data);
        } else {
          callUtils.handleICECandidateGroup(data);
        }
      });
      socketRef.current.on("offer-for-group-call", (data) => {
        const { callId, chatRoomId, createdAt, offer, to, userId } = data;
        if (callUtils.isCallOpenRef.current && callUtils.chatRoomid.current!=chatRoomId) {
          console.log(chatRoomId,'&&&',callUtils.chatRoomid.current,"isCallOpen: inside offer-for-group-call", isCallOpen);
            return
        }
         else {
          console.log(chatRoomId,'&&&',callUtils.chatRoomid.current,"isCallOpen: outside offer-for-group-call", isCallOpen);
          if (
            myAllOffers.current[chatRoomId] &&
            myAllOffers.current[chatRoomId][userId]
          ) {
            console.log(data, "myAllOffers.current:  2", myAllOffers.current);
            if (
              myAllOffers.current[chatRoomId][userId].createdAt > data.createdAt
            ) {
              console.log(data, "myAllOffers.current: 3", myAllOffers.current);
              callUtils.handleReAnswer(data);
            }
          } else {
            if (!allOffers.current[chatRoomId]) {
              setParticipants(data.participants);
            }
            allOffers.current[chatRoomId] = {
              [userId]: data,
              ...allOffers.current[chatRoomId],
            };
          }
        }
      });

      socketRef.current.on("start-group-call", (data) => {
        if (callUtils.isCallOpenRef.current) {
          console.log("isCallOpen:inside  start-group-call", isCallOpen);
          
          return
        }
        console.log("isCallOpen: outside start-group-call", isCallOpen);
        const { callId, chatRoomId, createdAt, offer, to, userId } = data;
        
        if (!allOffers.current[chatRoomId]) {
          setParticipants(data.participants);
        }
        allOffers.current[chatRoomId] = {
          [userId]: data,
          ...allOffers.current[chatRoomId],
        };
        console.log(allOffers.current[chatRoomId], "data group Call allOffer");
        callUtils.handleIncomingGroupCall(data, chatData);
        setIsCallOpen(true);
        isGroupCall.current = true;
      });
      // socket.on("ice-candidate", handleICECandidate);

      socketRef.current.on("call-ended", () => {
        console.log("call-ended: ");
        callUtils.endCall();
      });

      socketRef.current.on("exit-group-call", (data) =>
        callUtils.existGroupCall(data)
      );
  
      return () => {
        if (socketRef.current) {
          socketRef.current.off("call-ended");
          socketRef.current.off("receiveUserMessage");
          socketRef.current.off("receiveUserMessage");
          socketRef.current.off("userMessageStatusUpdated");
          socketRef.current.off("groupChatDetails");
          socketRef.current.off("userRemovedFromGroupChat");
          socketRef.current.off("groupChatRoomInitiated");
          socketRef.current.off("userRoleUpdated");
          socketRef.current.off("offer");
          socketRef.current.off("answer");
          socketRef.current.off("ice-candidate");
          socketRef.current.off("join-group-call");
          socketRef.current.off("ice-candidate-in-group-call");
          socketRef.current.off("offer-for-group-call");
          socketRef.current.off("callRejected"); // Cleanup on unmount
        }
      };
    }
  }, [selectedRoomId, chatData, messages]);

  // const handleChatDataStatus = (data)=>{
  //   let chatDataArray = chatData.map(item => {
  //      if (item.chatRoomId == selectedRoomId) {
  //        item.lastMessageDetails.messageStatus = data.messageStatus
  //        item.lastMessageDetails._id = data.messageId
  //        return item
  //      } else return item

  //    });
  //    if (chatDataArray.length > 0) {
  //      setChatData(chatDataArray)
  //    }
  //   }
  //    const handleMessage = (data) => {
  //     setMessages((prevMessageList) => {
  //      return prevMessageList.map((item) => {

  //        if (item.messageSentAt == data.messageSentAt) {
  //          handleChatDataStatus(data)
  //          return {
  //            ...item,
  //            messageId: data.messageId,
  //            messageStatus: data.messageStatus,
  //          };
  //        } else {
  //          return item;
  //        }
  //      });
  //    });
  //   }

  useEffect(() => {
    // Define the event handlers
    i18n.changeLanguage(language);

    !navigator.onLine && handleOfflineMode();
    // Add event listeners for online and offline
    window.addEventListener("online", handleOnlineMode);
    window.addEventListener("offline", handleOfflineMode);

    // Clean up the event listeners on unmount
    return () => {
      window.removeEventListener("online", handleOnlineMode);
      window.removeEventListener("offline", handleOfflineMode);
    };
  }, []);

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  const handleOnlineMode = async () => {
    setOnlineMode(true);
    socketRef.current?.connect();
  };
  const handleOfflineMode = () => {
    setOnlineMode(false);
    chatRefs.current = true;
    socketRef.current?.disconnect();
  };

  const handleLanguageDialogClose = (applyLanguage) => {
    // console.log("applyLanguage parameter:", applyLanguage);
    if (applyLanguage) {
      i18n.changeLanguage(selectedLanguage); // Apply the selected language
    }
    setLanguageDialogOpen(false);
  };

  const handleLanguageDialogOpen = () => {
    setLanguageDialogOpen(true);
    handleMenuClose();
  };

  const isTypingInArabic = (text) => {
    if (text === "" || text == undefined) return false;

    text = text.trim();

    // Check if the first character is in the range of RTL scripts
    if (text.length === 0) return false; // Handle empty strings

    const firstChar = text.charCodeAt(0);

    // Unicode ranges for common RTL scripts
    const isArabic = firstChar >= 0x0600 && firstChar <= 0x06ff;
    const isHebrew = firstChar >= 0x0590 && firstChar <= 0x05ff;
    const isPersian = firstChar >= 0x0750 && firstChar <= 0x077f; // Additional Persian range
    const isUrdu = firstChar >= 0x0600 && firstChar <= 0x06ff; // Urdu uses Arabic script

    return isArabic || isHebrew || isPersian || isUrdu;

    // Regex to detect Arabic characters including extended ranges
    // const arabicRegex =
    //   /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB70-\uFBFF\uFE70-\uFEFF]/;
    // return arabicRegex.test(text);
  };

  const handleCreateGroup = () => {
    // console.log("Create group clicked!");
    setIsGroupScreenVisible(true);
  };

  const handleCloseGroupScreen = () => {
    setIsGroupScreenVisible(false);
  };

  const handlesetgroupInfoPage = (value) => {
    setgroupInfoPage(value);
  };

  const handleOpenDialog = () => {
    // console.log("handleOpenDialoghandleOpenDialog is open");
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  // useEffect(()=>{
  //    sendMessageOffline()
  // },[chatData])

  const sendMessageOffline = async () => {
    if (db.current) {
      // console.log("indexedDB connected with you");
      const unsentMessages = await getMessagesFromDB(db.current, storeName);
      if (unsentMessages.length > 0) {
        // console.log("unsentMessages.length > 0: ");
        // Emit all unsent messages
        for (const message of unsentMessages) {
          const messageToSend = { ...message };
          messageToSend.senderId === userId && delete messageToSend.id;
          messageToSend.senderId === userId &&
            delete messageToSend.messageStatus;

          if (messageToSend.senderId === userId) {
            let chattype = messageToSend.chatType;
            delete messageToSend.chatType;
            chattype === "ONE_TO_ONE"
              ? socketRef.current.emit(socketEvent.sendMessage, messageToSend)
              : socketRef.current.emit(
                  "sendMessageInGroup",
                  messageToSend,
                  (response) => {
                    if (response.error) {
                      console.error("Validation failed:", response.error);
                    } else {
                      // console.log("User validated:", response);
                    }
                  }
                );
          }
          moveIdFirst(messageToSend);
          // Optionally, wait for a response or acknowledgment from the server
          // If you need to delete after sending, await the deletion
          messageToSend.senderId === userId &&
            (await deleteMessageFromDB(db.current, storeName, message.id));
        }
      }
    }
  };

  return (
    <ChatContainer>
      <Grid
        container
        sx={{ height: "auto", direction: i18n.language === "ar" ? "rtl" : "" }}
      >
        <Grid
          position={"relative"}
          item
          xs={12}
          sm={5}
          md={4}
          lg={3}
          sx={{
            width: "100%",
            boxSizing: "border-box",

            display: isSmScreen
              ? selectedRoomId
                ? "none"
                : isCallOpen
                ? "none"
                : "block"
              : "block",
            // padding: isSmScreen ? "0" : "0 10px 10px 0",
            height: "100vh",
            borderRight: "1px solid ",
            borderColor: AppTheme === "dark" ? "#222d34" : "#f0f2f5",
            // direction:i18n.language==='ar'?'rtl':'',
            // textAlign:'right'
          }}
        >
          {!groupInfoPage ? (
            <>
              {!isGroupScreenVisible ? (
                <>
                  <Box
                    sx={{
                      padding: "15px 12px 12px 12px",
                      boxShadow: "0px 0px 10px 5px rgba(0, 0, 0, 0.06)",
                      backgroundColor:
                        AppTheme === "dark" ? "#111b21" : "#ffffff",

                      height: "100vh",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: AppTheme === "dark" ? "#e9edef" : "#111B21",
                          fontSize: "22px",
                          paddingLeft: "10px",
                          fontWeight: "700",
                          "@media(max-width:767px)": {
                            fontSize: "22px !important",
                          },
                        }}
                      >
                        {t("chats")}
                      </Typography>

                      <IconButton
                        onClick={handleThemeDialogOpen}
                        sx={{
                          color: AppTheme === "dark" ? "#e9edef" : "#111B21",
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </Box>

                    <Box mt={2}>
                      <TextField
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            backgroundColor:
                              AppTheme === "dark" ? "#202c33" : "#f0f2f5",
                            //   color: '#8696a0',
                          },
                          "& .MuiInputBase-input": {
                            color: AppTheme === "dark" ? "#e9edef" : "#3b4a54", // Color for the input text
                            textAlign: isTypingInArabic(searchValue)
                              ? "right"
                              : "left", // Align text based on language
                            direction: isTypingInArabic(searchValue)
                              ? "rtl"
                              : "ltr",
                          },
                        }}
                        fullWidth
                        variant="outlined"
                        placeholder={t("search")}
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon style={{ color: "#8696a0" }} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>

                    {!onlineMode && (
                      <Box
                        mt={2}
                        sx={{
                          minHeight: "62px",
                          display: "flex",
                          alignItems: "center",
                          backgroundColor:
                            AppTheme === "dark" ? "#182229" : "#ffd279",
                          gap: "5px",
                        }}
                      >
                        <div>
                          <MdSignalWifiOff size={49} color="gray" />
                        </div>
                        <Box sx={{ padding: "15px 10px" }}>
                          <Typography
                            sx={{
                              fontWeight: "600",
                              fontSize: "16px",
                              color:
                                AppTheme === "light" ? "#111b21" : "#e9edef",
                              lineHeight: "21px",
                            }}
                          >
                            {t("computerNotConnected")}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              color:
                                AppTheme === "light" ? "#111b21" : "#8696a0",
                              lineHeight: "19px",
                            }}
                          >
                            {t("activeInternetConnection")}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                    <Box
                      mt={onlineMode ? 2 : ""}
                      sx={{
                        height: "calc(100vh - 150px)",
                        maxHeight: "100vh",
                        overflowY: "auto",
                        paddingBottom: "20px",
                        scrollbarWidth: "thin",
                        scrollbarColor:
                          AppTheme === "dark" ? "#3b4348 #111b21" : " #111b21",
                      }}
                    >
                      {!isLoading &&
                        (chatData?.filter((chat) => {
                          if (!searchValue) return true;
                          return chat?.chatType === "ONE_TO_ONE"
                            ? chat?.userDetails?.userName
                            : chat?.groupDetails?.groupName
                                ?.toLowerCase()
                                ?.includes(searchValue.toLowerCase());
                        }).length === 0 ? (
                          isChatListLoading ? (
                            <>
                              {" "}
                              <Stack spacing={1}>
                                {Array.from({ length: 10 }).map((_, index) => (
                                  <Skeleton
                                    variant="rectangular"
                                    width={"100%"}
                                    height={50}
                                    key={index}
                                  />
                                ))}
                              </Stack>
                            </>
                          ) : (
                            onlineMode && (
                              <Typography
                                align="center"
                                sx={{
                                  mt: 2,
                                  color:
                                    AppTheme === "dark" ? "white" : "black",
                                  fontSize: "14px",
                                }}
                              >
                                {t("userNotFound")}
                              </Typography>
                            )
                          )
                        ) : (
                          chatData
                            ?.filter((chat) => {
                              if (!searchValue) return true;
                              return chat?.chatType === "ONE_TO_ONE"
                                ? chat?.userDetails?.userName
                                : chat?.groupDetails?.groupName
                                    ?.toLowerCase()
                                    ?.includes(searchValue.toLowerCase());
                            })
                            .map((chat, index) => (
                              <Box
                                key={chat?.chatRoomId}
                                sx={{
                                  // minHeight:'75px',
                                  display: "flex",
                                  alignItems: "center",
                                  width: "100%",
                                  boxSizing: "border-box",
                                  gap: "5px",
                                  cursor: "pointer",

                                  backgroundColor:
                                    selectedRoomId === chat?.chatRoomId
                                      ? AppTheme === "dark"
                                        ? "#2a3942"
                                        : "#f0f2f5"
                                      : "inherit",

                                  color:
                                    selectedRoomId === chat?.chatRoomId
                                      ? AppTheme === "dark"
                                        ? "white"
                                        : "#111b21"
                                      : AppTheme === "dark"
                                      ? "inherit"
                                      : "white",
                                  "&:hover": {
                                    backgroundColor:
                                      AppTheme === "dark"
                                        ? "#263136"
                                        : "#d0d3d7",
                                  },
                                }}
                                onClick={() =>
                                  chat.chatRoomId != selectedRoomId &&
                                  handleChatItemClick(chat)
                                }
                              >
                                <Avatar
                                  src={
                                    chat?.chatType === "ONE_TO_ONE"
                                      ? chat?.userDetails?.profileUrl
                                      : chat?.groupDetails?.imageUrl
                                  }
                                  sx={{ width: 49, height: 49 }}
                                />
                                <Box
                                  sx={{
                                    width: "100%",
                                    boxSizing: "border-box",
                                    // flexGrow: 1,
                                    textAlign: "start",
                                    borderTop: index != 0 ? "1px solid" : 0,
                                    borderColor:
                                      AppTheme === "dark"
                                        ? "#222d34"
                                        : "#f0f2f5",
                                    padding: "10px",
                                    paddingBottom: "15px",
                                    paddingTop: "15px",
                                    overflow: "hidden",
                                    "&:hover": {
                                      borderTop: 0,
                                    },
                                  }}
                                >
                                  <Box
                                    sx={{
                                      boxSizing: "border-box",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      width: "100%",
                                      gap: "10px",
                                    }}
                                  >
                                    <Typography
                                      variant="body1"
                                      fontWeight="bold"
                                      sx={{
                                        direction: true ? "rtl" : "ltr", // Check if the language is Arabic
                                        textAlign: true ? "right" : "left", // Align text properly for Arabic
                                        color:
                                          AppTheme === "light"
                                            ? "#111b21"
                                            : "#e9edef",
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        direction:
                                          isTypingInArabic(
                                            chat?.chatType === "ONE_TO_ONE"
                                              ? chat?.userDetails?.userName + ""
                                              : chat?.groupDetails?.groupName +
                                                  ""
                                          ) === true
                                            ? "rtl"
                                            : "ltr",
                                        textAlign:
                                          isTypingInArabic(
                                            chat?.chatType === "ONE_TO_ONE"
                                              ? chat?.userDetails?.userName + ""
                                              : chat?.groupDetails?.groupName +
                                                  ""
                                          ) === true
                                            ? "right"
                                            : "left",
                                      }}
                                    >
                                      {chat?.chatType === "ONE_TO_ONE"
                                        ? chat?.userDetails?.userName
                                        : chat?.groupDetails?.groupName}
                                    </Typography>

                                    <Typography
                                      variant="caption"
                                      sx={{
                                        display: "flex",
                                        // textAlign:'right',
                                        color:
                                          chat?.lastMessageDetails?.senderId !==
                                            userId &&
                                          chat?.unreadMessagesCount > 0
                                            ? "#00a884"
                                            : "#8696a0",
                                        fontSize: "12px",
                                        marginRight: "6px",
                                        fontWeight: "400",
                                      }}
                                    >
                                      {formatTimestamp(
                                        chat?.lastMessageDetails?.createdAt
                                      )}
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      marginTop: "3px",
                                      width: "100%",
                                      boxSizing: "border-box",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        fontSize: "14px",
                                        color: "#8696a0",
                                        wordWrap: "break-word",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        fontWeight: "400",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        width: "90%",
                                      }}
                                    >
                                      {((chat.chatType != "GROUP" &&
                                        !chat?.userDetails?.isTyping) ||
                                        (chat.chatType == "GROUP" &&
                                          chat?.isTyping == "")) &&
                                        chat?.lastMessageDetails?.senderId ===
                                          userId && (
                                          <IconButton
                                            size="small"
                                            sx={{
                                              padding: "0px 0.1rem 0px 0px",
                                            }}
                                          >
                                            {chat?.lastMessageDetails
                                              ?.messageStatus ===
                                            "DELIVERED" ? (
                                              <DoneAllIcon
                                                sx={{
                                                  fontSize: "1rem",
                                                  color: "#8696a0",
                                                }}
                                              />
                                            ) : chat?.lastMessageDetails
                                                ?.messageStatus === "READ" ? (
                                              <DoneAllIcon
                                                sx={{
                                                  fontSize: "1rem",
                                                  color: "#53bdeb",
                                                }}
                                              />
                                            ) : chat?.lastMessageDetails
                                                ?.messageStatus ===
                                              "pending" ? (
                                              <WhatsAppClockIcon
                                                sx={{
                                                  fontSize: "1rem",
                                                  color:
                                                    AppTheme === "dark"
                                                      ? "#e9edef"
                                                      : "#8696a0",
                                                }}
                                              />
                                            ) : (
                                              <DoneIcon
                                                sx={{
                                                  fontSize: "1rem",
                                                  color: "#8696a0",
                                                }}
                                              />
                                            )}
                                          </IconButton>
                                        )}

                                      <Typography
                                        sx={{
                                          fontSize: "14px",
                                          fontWeight: "400",
                                          color:
                                            chat?.lastMessageDetails
                                              ?.senderId !== userId &&
                                            chat?.unreadMessagesCount > 0
                                              ? AppTheme === "dark"
                                                ? "#e9edef"
                                                : "#3b4a54"
                                              : "inherit",
                                          whiteSpace: "nowrap",
                                          textOverflow: "ellipsis",
                                          // overflow: "hidden",
                                          width: "100%",
                                          boxSizing: "border-box",
                                          flexGrow: 1,
                                          display: "flex",
                                          direction:
                                            isTypingInArabic(
                                              chat?.lastMessageDetails?.content
                                            ) === true
                                              ? "rtl"
                                              : "ltr",
                                          // textAlign:

                                          //  i18n.language === "ar"
                                          //     ? "end"
                                          //     : "end",
                                        }}
                                      >
                                        {!chat?.userDetails?.isTyping ? (
                                          chat?.chatType === "GROUP" &&
                                          chat?.isTyping?.length > 0 ? (
                                            <Typography
                                              variant="body1"
                                              sx={{
                                                fontFamily: `"SF Pro Text", "SF Pro Icons", system, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", Ubuntu, Cantarell, "Fira Sans", sans-serif`,
                                                fontWeight: "400",
                                                fontSize: "13px",
                                                lineHeight: "1.5",
                                                letterSpacing: "0.00938em",
                                                color: "#8696a0",
                                                width: "100%",
                                                // animation: "pulse 1.5s infinite"
                                                // padding: "0px 2px 2px 2px",
                                                // margin: "10px",
                                              }}
                                            >
                                              {chat?.isTyping}
                                              {/* {t("typing")} */}
                                              <span className="dot">.</span>
                                              <span className="dot">.</span>
                                              <span className="dot">.</span>
                                            </Typography>
                                          ) : (
                                            <Box
                                              sx={{
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                width:
                                                  isTypingInArabic(
                                                    chat?.lastMessageDetails
                                                      ?.content
                                                  ) === true
                                                    ? "100%"
                                                    : "90%",
                                                marginRight:
                                                  isTypingInArabic(
                                                    chat?.lastMessageDetails
                                                      ?.content
                                                  ) === true
                                                    ? "10px"
                                                    : "0px",
                                                // display:'flex'
                                              }}
                                            >
                                              {chat?.chatType == "GROUP"
                                                ? chat?.lastMessageDetails
                                                    ?.senderId == undefined
                                                  ? ""
                                                  : chat?.lastMessageDetails
                                                      ?.senderId == userId
                                                  ? "You : "
                                                  : `${chat?.lastMessageDetails?.userName} : `
                                                : ""}

                                              {chat?.lastMessageDetails
                                                ?.messageType == "VIDEO" ? (
                                                <span
                                                  style={{
                                                    // display: "flex",
                                                    // alignItems: "center",
                                                    color: "#8696A0",
                                                  }}
                                                >
                                                  <VideocamIcon
                                                    sx={{
                                                      fontSize: "18px",
                                                      //  marginRight: "3px",
                                                      verticalAlign: "bottom",
                                                    }}
                                                  />{" "}
                                                  {t("video")}
                                                </span>
                                              ) : chat?.lastMessageDetails
                                                  ?.messageType == "IMAGE" ? (
                                                <span
                                                  style={{
                                                    // display: "flex",

                                                    color: "#8696A0",
                                                  }}
                                                >
                                                  <CameraAltIcon
                                                    sx={{
                                                      fontSize: "17px",
                                                      marginRight: "3px",
                                                      verticalAlign: "bottom", // keeps icon vertically centered with text
                                                    }}
                                                  />
                                                  {t("photo")}
                                                </span>
                                              ) : chat?.lastMessageDetails
                                                  ?.messageType == "AUDIO" ? (
                                                <span
                                                  style={{
                                                    // display: "flex",
                                                    // alignItems: "center",
                                                    color: "#8696A0",
                                                  }}
                                                >
                                                  <MicIcon
                                                    sx={{
                                                      fontSize: "18px",
                                                      // marginRight: "3px",
                                                      verticalAlign: "bottom",
                                                    }}
                                                  />
                                                  {t("audio")}
                                                </span>
                                              ) : chat?.lastMessageDetails
                                                  ?.messageType == "FILE" ? (
                                                <span
                                                  style={{
                                                    // display: "flex",
                                                    // alignItems: "center",
                                                    color: "#8696A0",
                                                  }}
                                                >
                                                  <TextSnippetIcon
                                                    sx={{
                                                      fontSize: "18px",
                                                      marginRight: "3px",
                                                      verticalAlign: "bottom",
                                                    }}
                                                  />
                                                  {t("document")}{" "}
                                                </span>
                                              ) : (
                                                truncateText(
                                                  chat?.lastMessageDetails
                                                    ?.content || "",
                                                  isSmScreen
                                                    ? 15000000
                                                    : ismdScreen
                                                    ? 15000000
                                                    : 20000000
                                                )
                                              )}
                                            </Box>
                                          )
                                        ) : (
                                          <Typography
                                            variant="body1"
                                            sx={{
                                              fontFamily: `"SF Pro Text", "SF Pro Icons", system, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", Ubuntu, Cantarell, "Fira Sans", sans-serif`,
                                              fontWeight: "400",
                                              fontSize: "13px",
                                              lineHeight: "1.5",
                                              letterSpacing: "0.00938em",
                                              color: "#8696a0",
                                              // animation: "pulse 1.5s infinite"
                                              // padding: "0px 2px 2px 2px",
                                              // margin: "10px",
                                            }}
                                          >
                                            {t("typing")}
                                            <span className="dot">.</span>
                                            <span className="dot">.</span>
                                            <span className="dot">.</span>
                                          </Typography>
                                        )}
                                      </Typography>
                                    </Typography>
                                    {chat?.lastMessageDetails?.senderId !==
                                      userId && (
                                      <Badge
                                        badgeContent={
                                          chat?.unreadMessagesCount > 0
                                            ? chat.unreadMessagesCount
                                            : null
                                        } // Example badge count, replace with your logic
                                        // color="red"
                                        sx={{
                                          "& .MuiBadge-dot": {
                                            backgroundColor:
                                              chat?.unreadMessagesCount > 0
                                                ? "#00a884"
                                                : "transparent",
                                            animation:
                                              chat?.unreadMessagesCount > 0
                                                ? "pulse 2s infinite"
                                                : "none",
                                          },
                                          ".MuiBadge-badge": {
                                            right:
                                              i18n.language === "ar" ? -10 : 17,
                                            // top: 8,
                                            // minWidth: 18,
                                            // height: 18,
                                            padding: "4px",
                                            fontSize: "0.7rem",
                                            fontWeight: "bold",
                                            backgroundColor: "#00a884", // Customize badge color
                                            lineHeight: "0px",
                                          },
                                        }}
                                      ></Badge>
                                    )}
                                  </Box>
                                </Box>
                              </Box>
                            ))
                        ))}
                    </Box>
                  </Box>

                  {chatData.length > 0 && (
                    <Box
                      sx={{
                        position: "absolute", // Change to absolute positioning
                        bottom: "50px", // Space from the bottom
                        // right: '30px', // Space from the left (inside user list area)
                        left: i18n.language === "ar" ? "30px" : "auto",
                        right: i18n.language === "ar" ? "auto" : "30px",
                        zIndex: 1000, // Ensure it appears above other components
                      }}
                    >
                      <Fab
                        color="primary" // You can change the color as needed
                        onClick={handleCreateGroup}
                        sx={{
                          // boxShadow: '3px 3px 8px black',
                          width: "50px", // Set width for square shape
                          height: "50px", // Set height for square shape
                          borderRadius: "50%", // Adjust border radius
                          padding: 0, // Remove default padding
                          minWidth: 0, // Prevent minimum width enforcement
                          "&:hover": {
                            backgroundColor: "primary.main", // Keep the hover color the same as the default
                          },
                        }}
                      >
                        <AddIcon />
                      </Fab>
                    </Box>
                  )}
                </>
              ) : (
                <Box
                  sx={{
                    boxShadow: "0px 0px 10px 5px rgba(0, 0, 0, 0.06)",
                    backgroundColor:
                      AppTheme === "dark" ? "#111b21" : "#ffffff",
                    // borderRadius: "15px",
                    height: "100vh",
                  }}
                >
                  <AddGroupMembersScreen
                    onClose={handleCloseGroupScreen}
                    AppTheme={AppTheme}
                    socketRef={socketRef}
                    userId={userId}
                    groupSelected={groupSelected}
                    setGroupUsers={setGroupUsers}
                    appKey={appKey}
                    setGroupCreated={setGroupCreated}
                  />
                </Box>
              )}
            </>
          ) : (
            <>
              <GroupInfoPage
                appKey={appKey}
                userId={userId}
                AppTheme={AppTheme}
                handlesetgroupInfoPage={handlesetgroupInfoPage}
                handleOpenDialog={handleOpenDialog}
                onClose={handleCloseDialog}
                handleCloseDialog={handleCloseDialog}
                socketRef={socketRef}
                chatRoomId={selectedRoomId}
                groupIcon={groupIcon}
                setGroupIcon={setGroupIcon}
                newGroupName={newGroupName}
                setNewGroupName={setNewGroupName}
                members={members}
                setGroupMembers={setGroupMembers}
                groupAdminName={groupAdminName}
                groupCreationDate={groupCreationDate}
              />
            </>
          )}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem sx={{ fontSize: "14px" }} onClick={handleThemeDialogOpen}>
              {t("theme")}
            </MenuItem>
            {/* <MenuItem
              sx={{ fontSize: "14px" }}
              onClick={handleFontSizeDialogOpen}
            >
              Font Size
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} onClick={handleMenuClose}>
              Wallpaper
            </MenuItem> */}
            <MenuItem
              sx={{ fontSize: "14px" }}
              onClick={handleLanguageDialogOpen}
            >
              {t("appLanguage")}
            </MenuItem>
          </Menu>

          <Dialog
            open={languageDialogOpen}
            onClose={() => handleLanguageDialogClose(false)}
          >
            <DialogTitle>{t("Select_Language")}</DialogTitle>
            <DialogContent>
              <RadioGroup
                value={selectedLanguage}
                onChange={handleLanguageChange}
              >
                <FormControlLabel
                  value="en"
                  control={<Radio />}
                  label={t("english")}
                />
                <FormControlLabel
                  value="hi"
                  control={<Radio />}
                  label={t("hindi")}
                />
                <FormControlLabel
                  value="ar"
                  control={<Radio />}
                  label={t("arabic")}
                />
              </RadioGroup>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleLanguageDialogClose(false)}>
                {t("Cancel")}
              </Button>
              <Button onClick={() => handleLanguageDialogClose(true)}>
                {t("OK")}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={themeDialogOpen} onClose={handleThemeDialogClose}>
            <DialogTitle>{t("Select_Theme")}</DialogTitle>
            <DialogContent>
              <RadioGroup value={selectedTheme} onChange={handleThemeChange}>
                <FormControlLabel
                  value="light"
                  control={<Radio />}
                  label={t("light")}
                />
                <FormControlLabel
                  value="dark"
                  control={<Radio />}
                  label={t("dark")}
                />
              </RadioGroup>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleThemeDialogClose(false)}>
                {t("Cancel")}
              </Button>
              <Button onClick={() => handleThemeDialogClose(true)}>
                {t("OK")}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={fontSizeDialogOpen}
            onClose={() => handleFontSizeDialogClose(false)}
          >
            <DialogTitle>Select Font Size</DialogTitle>
            <DialogContent>
              <RadioGroup
                value={selectedFontSize}
                onChange={handleFontSizeChange}
              >
                <FormControlLabel
                  value="small"
                  control={<Radio />}
                  label="Small"
                />
                <FormControlLabel
                  value="medium"
                  control={<Radio />}
                  label="Medium"
                />
                <FormControlLabel
                  value="large"
                  control={<Radio />}
                  label="Large"
                />
              </RadioGroup>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleFontSizeDialogClose(false)}>
                Cancel
              </Button>
              <Button onClick={() => handleFontSizeDialogClose(true)}>
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>

        <Grid
          item
          xs={12}
          sm={7}
          md={8}
          lg={9}
          sx={{
            display: isSmScreen
              ? selectedRoomId
                ? "block"
                : isCallOpen
                ? "block"
                : "none"
              : "block",
            // padding: isSmScreen ? "0" : "0 0 10px 10px",
            height: "100vh",
            // direction:i18n.language==='ar'?'rtl':'',
          }}
        >
          {isCallOpen && selectedRoomId == null ? (
            <VideoChat
              socketRef={socketRef}
              userid={userId}
              chatData={chatData}
              // chatRoomid={selectedRoomId}
            />
          ) : selectedRoomId ? (
            <ChatScreen
              appKey={appKey}
              open={true}
              handleClose={setSelectedRoomId}
              receiverId={receiverId}
              receiverName={receiverName}
              userid={userId}
              selectedRoomId={selectedRoomId}
              socketRef={socketRef}
              receiverDetails={receiverDetails}
              setMessages={setMessages}
              messages={messages}
              db={db.current}
              AppTheme={AppTheme}
              fontSize={fontSize}
              onlineMode={onlineMode}
              mediaFileInput={mediaFileInput}
              setMediaFileInput={setMediaFileInput}
              isCameraOn={isCameraOn}
              setIsCameraOn={setIsCameraOn}
              setCaption={setCaption}
              caption={caption}
              changeUser={changeUser}
              profileImg={profileImg}
              setChangeUser={setChangeUser}
              chatType={chatType}
              handleCreateGroup={handleCreateGroup}
              handlesetgroupInfoPage={handlesetgroupInfoPage}
              handleOpenDialog={handleOpenDialog}
              handleCloseDialog={handleCloseDialog}
              dialogOpen={dialogOpen}
              participants={participants}
              setReceiverName={setReceiverName}
              setProfileImg={setProfileImg}
              setparticipants={setparticipants}
              chatData={chatData}
              setChatData={setChatData}
              typingStatus={typingStatus}
              setTypingStatus={setTypingStatus}
              setGroupUsers={setGroupUsers}
              groupUsers={groupUsers}
              typingInGroup={typingInGroup}
              groupIcon={groupIcon}
              setGroupIcon={setGroupIcon}
              newGroupName={newGroupName}
              setNewGroupName={setNewGroupName}
              members={members}
              setGroupMembers={setGroupMembers}
              groupInfoPage={groupInfoPage}
              groupAdminName={groupAdminName}
            />
          ) : (
            chatData.length > 0 && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
                sx={{
                  backgroundColor: AppTheme === "dark" ? "#222e35" : "#f0f2f5",
                  color: AppTheme === "dark" ? "white" : "black",
                }}
              >
                {onlineMode && (
                  <Typography variant="body1" sx={{ fontSize: "30px" }}>
                    {t("selectChat")}
                  </Typography>
                )}
              </Box>
            )
          )}
        </Grid>
      </Grid>
    </ChatContainer>
  );
};

export default MessageScreen;

import React, { useState,useEffect } from "react";
import {
  Box,
  Typography,
  IconButton,
  TextField,
  List,
  ListItem,
  ListItemText,
  Avatar,
  Checkbox,
  Button,
  Skeleton
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import NewGroupPage from "./NewGroupPage";
import { LocalConvenienceStoreOutlined } from "@mui/icons-material";
import { Stack } from "@mui/system";

const AddGroupMembersScreen = ({ setGroupCreated,onClose, AppTheme,socketRef,userId,groupSelected,setGroupUsers,appKey }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const { t, i18n } = useTranslation();
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [selectedUserName,setSelectedUserName] = useState(['You'])
  const [newGroupPage,setNewGroupPage] = useState(false);
  const [users,setUsers]=useState([])
  const [loading,setLoading]=useState(false)
  
  const handleCheckboxChange = (event,userName) => {
    const userId = event.target.value;
    
    // Check if the userId is already selected
    if (selectedUserIds.includes(userId)) {
      // If the user is already selected, remove them from the array
      setSelectedUserIds(selectedUserIds.filter((id) => id !== userId));
      setSelectedUserName(selectedUserName.filter((name)=> name != userName))
    } else {
      // Otherwise, add the user to the array
      setSelectedUserIds([...selectedUserIds, userId]);
      setSelectedUserName([...selectedUserName, userName]);
    }
    // selectedUserName
  };
  
  const showSelectedMember = ()=>{
    let sortedUser = selectedUserName.sort((a, b) => a.localeCompare(b))
    // console.log("sortedUser: ", sortedUser);
    setGroupUsers(sortedUser)
    setSelectedUserName(['You'])
  }
 
 
 
  const filteredUsers = users?.filter((user) =>
    user.userName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleNewGroupOpen= ()=>{
    setNewGroupPage(true)
  }

  const handleNewGroupClose= ()=>{
    setNewGroupPage(false)
  }
 
 useEffect(()=>{
   if(socketRef.current){
    setLoading(true)
    let socketdata={
      'appKey': appKey,
      'userId':userId   
    }
    socketRef.current.emit("listAllUsers",socketdata,(response) => {
      if (response.error) {
        console.error("Validation failed:", response.error);
      } else {
        // console.log("User validated:", response);
      }    
    })
    socketRef.current.on('allUsersList',handleAllUsersList)
   }
   
   return () => {
    if(socketRef.current)
    socketRef.current.off("allUsersList");
    
  };
 },[])


  const handleAllUsersList = (data) => {

        // console.log('Received allUsersList event data:', data?.userList);
        data?.userList.sort((a, b) => a.userName.localeCompare(b.userName));    
          setUsers(data?.userList); // Set state if data.userList is an array
          setLoading(false);
      };

      const isTypingInArabic = (text) => {

        if (text === "" || text== undefined) return false;

        text = text.trim();
      
        // Check if the first character is in the range of RTL scripts
        if (text.length === 0) return false; // Handle empty strings
      
        const firstChar = text.charCodeAt(0);
        
        // Unicode ranges for common RTL scripts
        const isArabic = (firstChar >= 0x0600 && firstChar <= 0x06FF);
        const isHebrew = (firstChar >= 0x0590 && firstChar <= 0x05FF);
        const isPersian = (firstChar >= 0x0750 && firstChar <= 0x077F); // Additional Persian range
        const isUrdu = (firstChar >= 0x0600 && firstChar <= 0x06FF); // Urdu uses Arabic script
      
        return isArabic || isHebrew || isPersian || isUrdu;


        // if (text === "") return true;
        // // Regex to detect Arabic characters including extended ranges
        // const arabicRegex =
        //   /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB70-\uFBFF\uFE70-\uFEFF]/;
        // return arabicRegex.test(text);
      };
 
  return (
    <>
   
    {!newGroupPage &&<Box
      sx={{
        boxSizing: 'border-box',
        position: "absolute",
        padding: "15px 12px 12px 12px",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: AppTheme === "dark" ? "#111b21" : "#ffffff",
        
        transform: "translateX(100%)",
        animation: "slideIn 0.3s forwards",
      }}
    >
      {/* Close Button */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography sx={{ fontSize: '16px', color: AppTheme === "dark" ? "#e9edef" : "#111B21", padding: '0 0 0 10px' }}>
          {t("addGroupMembers")}
        </Typography>
        <IconButton
          onClick={onClose}
          sx={{ color: "#8696a0", padding: '8px' }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      {/* Search TextField */}
      <Box mt={2}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder={t("searchName")}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            sx: {
              color: "blue",
              fontSize: "16px",
            },
          }}
          InputLabelProps={{
            sx: {
              color: "gray",
              fontSize: "14px",
            },
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              backgroundColor: AppTheme === "dark" ? "#202c33" : "#f0f2f5",
            },
            "& .MuiInputBase-input": {
              color: AppTheme === "dark" ? "#e9edef" : "#3b4a54",
              // textAlign:
              //         i18n.language === "ar" && isTypingInArabic(searchTerm)
              //           ? "right"
              //           : "left", // Align text based on language
                    direction:
                       isTypingInArabic(searchTerm)
                        ? "rtl"
                        : "ltr",
                        "&::placeholder": {
                          textAlign: i18n.language==='en'?"left":'right', // Force placeholder text to align left
                        },
            },
          }}
        />
      </Box>

      <Box
        mt={2}
        sx={{
          
          height: "calc(100vh - 200px)",
          maxHeight: "100vh",
          overflowY: "auto",
          paddingBottom: "0px",
          scrollbarWidth: "thin",
          scrollbarColor: AppTheme === "dark" ? "#3b4348 #111b21" : "#111b21",
        }}
      >
        {
        loading?    
        <> 
        <Stack spacing={1}>
        {Array.from({ length: 10 }).map((_, index) => (
<Skeleton variant="rectangular" width={"100%"} height={60}  key={index} />
))}
       </Stack>
       </>
        :
        filteredUsers.length === 0 ? 
        (
          <Typography
            align="center"
            sx={{
              mt: 2,
              color: AppTheme === "dark" ? "white" : "black",
              fontSize: "14px",
            }}
          >
            {t("userNotFound")}
          </Typography>
        ) :
         (
          filteredUsers.map((user) => (
            <ListItem key={user.userId} sx={{ padding: "10px", alignItems: "center",width:'100%' }}>
             <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          {/* Left Box for Avatar and Name */}
          <Box sx={{ display: 'flex', alignItems: 'center',  whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        overflow: "hidden",  }}>
            <Avatar sx={{  marginLeft: i18n.language === "ar" ? '10px' : '0', 
    marginRight: i18n.language === "ar" ? '0' : '10px' }}>{user.userName[0]}</Avatar>
            <ListItemText
              primary={user.userName}
              primaryTypographyProps={{
                sx: {
                  fontSize: '16px', // Set the font size here
                  color: AppTheme === "dark" ? "white" : "#111b21",
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: "hidden", 
                  direction:  (isTypingInArabic(user.userName)===true)? 'rtl' : 'ltr', 
                  textAlign: i18n.language==='en'?"left":'right',
                },
              }}
            />
          </Box>
          {/* Right Box for Checkbox */}
          <Box>
            <Checkbox
              value={user.userId} // Set the value to the user's id (as a string)
              checked={selectedUserIds.includes(user.userId.toString())} // Ensure the comparison is done as strings
              onChange={(e)=>{
                handleCheckboxChange(e,user.userName)
              }} // Handle change event
            />
          </Box>
        </Box>
            </ListItem>
          ))
        )
        }
      </Box>
       {/* Done Button */}
       <Box
        sx={{
          display: "flex",
          justifyContent: "center",
                
          position: "fixed",
          bottom: 0,
          left: i18n.language === "ar" ? 0 : "auto", 
          right: i18n.language === "ar" ? "auto" : 0,
          width: "100%",
          padding: "15px",
          backgroundColor: AppTheme === "dark" ? "#111b21" : "#ffffff",
          boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.2)",
          zIndex: 1000,
        }}
      >
        <Button
        //   variant="contained"
          sx={{
            backgroundColor:'#00A884',
            color: "#ffffff",
            
            // padding: '8px 16px',   
            "&:hover": {
                backgroundColor: "#00A884",
              }, 
              borderRadius:'15px'
          }}
          onClick={() => {
            // console.log("Selected User IDs:", selectedUserIds); // Log selected user IDs
            // Perform any other actions you want to take when clicking 'Done'
           handleNewGroupOpen()
            
          }}
          disabled={!selectedUserIds.length>0}
        >
          {t("done")}
        </Button>
      </Box>
    </Box>}
   {newGroupPage && <NewGroupPage  setGroupCreated={setGroupCreated} appKey={appKey} onBack={handleNewGroupClose} AppTheme={AppTheme} selectedUserIds={selectedUserIds} socketRef={socketRef} userId={userId} groupSelected={groupSelected} showSelectedMember={showSelectedMember}/>}
    </>
  );
};

// Slide-in animation CSS
const slideInAnimation = `
  @keyframes slideIn {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
`;

// Inject animation CSS into the page
const styleElement = document.createElement("style");
styleElement.textContent = slideInAnimation;
document.head.appendChild(styleElement);

export default AddGroupMembersScreen;

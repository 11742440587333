import React, { useState ,useEffect, useRef} from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Checkbox,
  Button,
  TextField,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
const AddMembersDialog = ({appKey, open, onClose,  AppTheme,socketRef,selectedRoomId,userId,handleCheckboxChange,selectedUserIds,setSelectedUserIds }) => {
  // const [selectedMembers, setSelectedMembers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const { t, i18n } = useTranslation();
  const [users,setusersList]=useState(null)
 
 

  let newuserObjects=useRef(null);
  // useEffect(()=>{
   

  //  if(socketRef.current)
  //    socketRef.current.on('groupChatRoomInitiated',(data)=>{
      // onClose();
  // })
  // return () => {
  //   socketRef.current.off("groupChatRoomInitiated");   
    
  // };
  // },[])


  // useEffect(()=>{
  //   if(socketRef.current){
     
      
  //     socketRef.current.on('groupChatDetails',(data)=>{
  //       onClose();
      
       
  //     })
  //   }
  //    return ()=>{
  //     if(socketRef.current)
  //     socketRef.current.off('groupChatDetails')
  //    }
  // },[])
  

  const handleAddMembers = () => {
    // console.log('euiouihguiweh',selectedUserIds)
    onClose();
    onAddMembers();
     // Close the dialog after adding members
  };

  const onAddMembers=()=>{
    const userObjects = selectedUserIds.map(userId => ({
      userId: userId,
      userRole: "USER" // Assuming userRole is the same for all
  }))
 
    let roomData={
      "appKey":appKey,
      chatRoomId:selectedRoomId,
      // "groupName":groupName.trim(),
      // 'imageUrl':profileImgUrl.current,
      'userDetails':userObjects
    }
    socketRef.current.emit('initiateGroupChatRoom',roomData,(response) => {
      if (response.error) {
        if(response.success===false){
          toast.error(response.message)
        }
        console.error("Validation failed:", response.error);
      } else {
        if(response.success===true){
          // onClose()
          
        
          // toast.success(response.message)
        }
        // console.log("User validated:", response);
      }
    })
    setSelectedUserIds([])
  }

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  // Filter users based on the search query
  const filteredUsers = users?.filter((user) =>
    user.userName.toLowerCase().includes(searchQuery)
  );
  
  const isTypingInArabic = (text) => {


    if (text === "" || text== undefined) return false;

    text = text.trim();
  
    // Check if the first character is in the range of RTL scripts
    if (text.length === 0) return false; // Handle empty strings
  
    const firstChar = text.charCodeAt(0);
    
    // Unicode ranges for common RTL scripts
    const isArabic = (firstChar >= 0x0600 && firstChar <= 0x06FF);
    const isHebrew = (firstChar >= 0x0590 && firstChar <= 0x05FF);
    const isPersian = (firstChar >= 0x0750 && firstChar <= 0x077F); // Additional Persian range
    const isUrdu = (firstChar >= 0x0600 && firstChar <= 0x06FF); // Urdu uses Arabic script
  
    return isArabic || isHebrew || isPersian || isUrdu;


    // if (text === "") return true;
    // // Regex to detect Arabic characters including extended ranges
    // const arabicRegex =
    //   /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB70-\uFBFF\uFE70-\uFEFF]/;
    // return arabicRegex.test(text);
  };
   
 
  const handleAllUsersList = (data) => {
    data?.userList.sort((a, b) => a.userName.localeCompare(b.userName)); 
    setusersList(data?.userList)
  };

  useEffect(()=>{
    
    
    if(socketRef.current){
     let socketdata={
       'appKey': appKey,
        'chatRoomId':selectedRoomId,
        'userId':userId
     }
     socketRef.current.emit("listAllUsers",socketdata,(response) => {
       if (response.error) {
         console.error("Validation failed:", response.error);
       } else {
        //  console.log("User validated:", response);
       }
     })
     socketRef.current.on('allUsersList',handleAllUsersList)
    }
  
    return () => {
     if(socketRef.current)
     socketRef.current.off("allUsersList");
     
   };
  },[])

  return (
    <Dialog
      open={open}
      onClose={()=>{ setSelectedUserIds([]); onClose()}}
      maxWidth= {"300px"}
     
      aria-labelledby="add-members-dialog-title"
      PaperProps={{
        sx: {
          backgroundColor: AppTheme === "dark" ? "#202c33" : "#f0f2f5",
          color: AppTheme === "light" ? "#111b21" : "#e9edef",
          maxWidth: "300px", // Add maxWidth for the dialog box
          width: "100%", 
          borderRadius:'5px'
        },
      }}
    >
      {/* Dialog Title with Close Icon */}
      <DialogTitle id="add-members-dialog-title" sx={{paddingTop:'10px',paddingBottom:'10px'}}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography
            sx={{
              fontSize: "18px", // Customize font size for title
              color: AppTheme === "light" ? "#111b21" : "#e9edef",
            }}
          >
            {t('addMembers')}
          </Typography>
          <IconButton edge="end" onClick={onClose}>
            <Close sx={{ color: AppTheme === "light" ? "#111b21" : "#e9edef" }} />
          </IconButton>
        </Box>
      </DialogTitle>

      {/* Search Bar */}
      <DialogContent dividers>
      <TextField
  variant="outlined"
  placeholder={`${t('searchUsers')}...`}

  fullWidth
  value={searchQuery}
  onChange={handleSearchChange}
  sx={{
    "& .MuiOutlinedInput-root": {
      backgroundColor:
        AppTheme === "dark" ? "#202c33" : "#f0f2f5",
      //   color: '#8696a0',
      "&:hover fieldset": {
        borderColor: AppTheme === "dark" ? "#667781" : "#111b21", // Change to your desired hover border color
      },
      // Border color when focused
      "&.Mui-focused fieldset": {
        borderColor: "transparent", // Remove border color on click
      },
    },
   
    "& .MuiInputBase-input": {
      color: AppTheme === "dark" ? "#e9edef" : "#3b4a54", // Color for the input text
      // textAlign:
      //   i18n.language === "ar" &&
      //   isTypingInArabic(searchQuery)
      //     ? "right"
      //     : "left", // Align text based on language
      direction:
       
        isTypingInArabic(searchQuery)
          ? "rtl"
          : i18n.language === 'en' ? "ltr" : "rtl",
          "&::placeholder": {
            textAlign: i18n.language === 'en' ? "left" : "right", // Align placeholder based on language
            color: AppTheme === "dark" ? "#e9edef" : "#3b4a54",  // Optional: match the placeholder color with the theme
          },
    },
    
  }}
  InputProps={{
    style: {
      color: AppTheme === "dark" ? "#e9edef" : "#111b21", // Text color inside input
    },
  }}
  InputLabelProps={{
    style: {
      color: AppTheme === "dark" ? "black" : "#667781", // Placeholder color based on theme
    },
  }}
/>


        {/* User List with Custom Scrollbar Styles */}
        <List
  sx={{
    width: '100%',
    direction: i18n.language === "ar" ? "rtl" : "ltr",
    maxHeight: "300px",
    overflowY: "auto",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#3b4348",
      borderRadius: "8px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    scrollbarWidth: "thin",
    scrollbarColor: AppTheme === "dark" ? "#3b4348 #111b21" : "#111b21",
  }}
>
  {filteredUsers?.map((user) => (
    <ListItem
      key={user._id}
      sx={{
        backgroundColor: AppTheme === "dark" ? "#2a3942" : "#ffffff",
        marginBottom: "8px",
        borderRadius: "8px",
        color: AppTheme === "light" ? "#111b21" : "#e9edef",
        width: '100%'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          // flexDirection: i18n.language === "en" ? 'row-reverse' : 'row', // Change direction for RTL
          justifyContent: 'space-between', // Ensures space between elements
          width: '100%', // Make it take full width
        }}
      >
<Box sx={{display:'flex',flexDirection:'row',width: '100%',alignItems:'center',justifyContent:'center'}}>
 
        <ListItemAvatar>
          <Avatar src={user.profileUrl}  />
        </ListItemAvatar>
        
        <ListItemText
          primary={user.userName}
          primaryTypographyProps={{
            fontSize: "16px",
            color: AppTheme === "light" ? "#111b21" : "#e9edef",
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: "hidden", 
            
            // maxWidth: '200px',
            
              textAlign:i18n.language === "ar"?'start':'',
          }}
        />


         <Checkbox
            edge="end"
            onChange={handleCheckboxChange}
            value={user.userId}
            checked={selectedUserIds.includes(user.userId.toString())}
            sx={{
              color: AppTheme === "light" ? "#111b21" : "#e9edef",
            }}
          />


        </Box>
        
      
        
       
      </Box>
    </ListItem>
  ))}
</List>




      </DialogContent>

      {/* Actions: Cancel and Add Members */}
      <DialogActions>
        <Button
          onClick={handleAddMembers}
          sx={{
            color: AppTheme === "light" ? "#111b21" : "#e9edef",
            backgroundColor: AppTheme === "dark" ? "#3b4a54" : "#ffffff",
            border: "1px solid",
            borderColor: AppTheme === "dark" ? "#667781" : "#e9edef",
          }}
          disabled={selectedUserIds.length<1}
        >
          Add Members
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddMembersDialog;

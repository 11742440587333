import React from 'react';
import ChatCard from './Components/ChatCard';
// import './App.module.css';


function App() {
 
  return (   
   
    <div >
       <ChatCard/>
     </div>
   
  );
}

export default App;
